import { Box } from '@mui/material'
import DataGrid from 'components/molecules/DataGrid'
import Header from 'components/molecules/Header'
import { useAuth } from 'contexts/AuthContext'
import { DefaultRole } from 'domains/roles/enums'
import { useUserRunsBaseQuery } from 'domains/userRuns/queries'
import { USER_RUNS_BASE } from 'domains/userRuns/templates'
import { RUN_DASHBOARD_PATH } from 'enums/paths'
import { formatFiltersToQueryString } from 'formatters/filters'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import urlcat from 'urlcat'
import { filterUserRuns } from './filters'
import useUserRunsGrid from './grid'

function UserRunsList() {
  const { t } = useTranslation()
  const { columns, defaultSort } = useUserRunsGrid()
  const navigate = useNavigate()

  const openStatisticForUserRun = (params) => {
    navigate(urlcat(RUN_DASHBOARD_PATH, { id: params.id }))
  }
  const queryClient = useQueryClient()

  const { is, user } = useAuth()
  const isSupervisorOrAdmin =
    is(DefaultRole.SUPERVISOR) || is(DefaultRole.ADMIN)
  const isCustomer = is(DefaultRole.CUSTOMER)
  const baseFilters = filterUserRuns(isSupervisorOrAdmin, isCustomer, user)

  return (
    <>
      <Header title={t('history')} />

      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <DataGrid
          id="user-runs"
          getEntities={useUserRunsBaseQuery}
          sx={{ flex: 1 }}
          columns={columns}
          getRowId={(row) => row.id}
          componentsProps={{
            toolbar: {
              onRefresh: () => {
                queryClient.invalidateQueries({ queryKey: [USER_RUNS_BASE] })
              },
            },
          }}
          defaultSort={defaultSort}
          onRowClick={openStatisticForUserRun}
          baseFilters={formatFiltersToQueryString(baseFilters)}
        />
      </Box>
    </>
  )
}

export default UserRunsList
