import { AddBox } from '@mui/icons-material'
import { Box, IconButton, Tooltip } from '@mui/material'
import DataGrid from 'components/molecules/DataGrid'
import { useAuth } from 'contexts/AuthContext'
import { useUsersQuery } from 'domains/users/queries'
import { USERS } from 'domains/users/templates'
import { USERS_CREATE_PATH } from 'enums/paths'
import { isAdmin } from 'helpers/role'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import useUsersGrid from './grid'

function UsersManager() {
  const { columns, defaultSort } = useUsersGrid()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const { user } = useAuth()

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <DataGrid
        id="user-manager"
        getEntities={useUsersQuery}
        sx={{ flex: 1 }}
        columns={columns}
        getRowId={(row) => row.id}
        componentsProps={{
          toolbar: {
            onRefresh: () => {
              queryClient.invalidateQueries({ queryKey: [USERS] })
            },
            additionalActions: [
              isAdmin(user.roles[0].key) && (
                <Tooltip key="add-type" title={t('add-type')}>
                  <IconButton
                    size="small"
                    onClick={() => navigate(USERS_CREATE_PATH)}
                  >
                    <AddBox />
                  </IconButton>
                </Tooltip>
              ),
            ],
          },
        }}
        defaultSort={defaultSort}
      />
    </Box>
  )
}

export default UsersManager
