import { yupResolver } from '@hookform/resolvers/yup'
import { Partner } from 'domains/partners/types'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

function useEditPartnerForm(partner: Partner) {
  const { t } = useTranslation()

  const defaultValues = {
    name: '',
    runs: [],
    logo: null,
    customers: [],
    ...partner,
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('required-field')),
    logo: Yup.object().required(t('required-field')).nullable(),
  })
  return useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })
}

export default useEditPartnerForm
