import { Box, Chip } from '@mui/material'
import { formatDistanceToChip } from 'domains/thematics/formatters'
import React from 'react'
import PlaceIcon from '@mui/icons-material/Place'
import { Person } from '@mui/icons-material'

function RunCardInfos({ run }) {
  return (
    <Box textAlign="center" sx={{ width: '90%' }}>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          justifyContent: 'center',
        }}
      >
        <Chip
          sx={{ bgcolor: 'background.default', color: 'primary.main' }}
          label={formatDistanceToChip(run.distance)}
        />
      </Box>
      <Box mt={1}>
        {run.town && (
          <Chip
            avatar={<PlaceIcon style={{ color: 'white' }} />}
            sx={{
              bgcolor: 'common.black',
              color: 'common.white',
              mr: 1,
              mb: 0.5,
            }}
            label={`${run.town?.name} (${run.town.postalCode})`}
          />
        )}
        {run.customer && (
          <Chip
            avatar={<Person style={{ color: 'white' }} />}
            sx={{ bgcolor: 'common.black', color: 'common.white' }}
            label={run.customer?.name}
          />
        )}
      </Box>
    </Box>
  )
}

export default RunCardInfos
