import { useMutationWrapper } from 'domains/helpers'
import { AVAILABILITIES, AVAILABILITY } from './templates'

export function useUpdateAvailabilityMutation(options?) {
  return useMutationWrapper('PUT', AVAILABILITY, options)
}

export function usePatchAvailabilityMutation(options?) {
  return useMutationWrapper('PATCH', AVAILABILITY, options)
}

export function useDeleteAvailabilityMutation(options?) {
  return useMutationWrapper('DELETE', AVAILABILITY, options)
}

export function useCreateAvailabilityMutation(options?) {
  return useMutationWrapper('POST', AVAILABILITIES, options)
}
