import { isEmpty } from 'lodash'

export function startFeelingGiven(feeling) {
  return !isEmpty(feeling)
}

export function endFeelingGiven(feeling) {
  return (
    !isEmpty(feeling?.endEmotion) ||
    !isEmpty(feeling?.endThought) ||
    !isEmpty(feeling?.endPhysicalLevels)
  )
}
