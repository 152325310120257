import { Close } from '@mui/icons-material'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material'
import { isEmpty } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import OfferTypeForm from '../Form'

function OfferTypeDialog(props) {
  const { open, onClose, offerTypeId } = props
  const isCreation = isEmpty(offerTypeId)
  const { t } = useTranslation()

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {isCreation ? t('add-offer-type') : t('update-offer-type')}
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ '&.MuiDialogContent-root': { pt: 1 } }}>
        <OfferTypeForm onClose={onClose} offerTypeId={offerTypeId} />
      </DialogContent>
    </Dialog>
  )
}
export default OfferTypeDialog
