import Box from '@mui/material/Box'
import MUIDrawer from '@mui/material/Drawer'
import orangeRouteUrl from 'assets/svg/orange-route.svg'
import * as React from 'react'
import MenuItems from '../MenuItems'
import ProfileMenu from '../ProfileMenu'
import Title from '../Title'

function Drawer({ open, setOpen, drawerWidth }) {
  return (
    <Box
      component="div"
      sx={{
        width: { sm: drawerWidth },
        flexShrink: { sm: 0 },
      }}
    >
      <MUIDrawer
        variant="temporary"
        open={open}
        onClose={() => setOpen(false)}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            backgroundColor: 'primary.main',
            backgroundImage: `url(${orangeRouteUrl})`,
            backgroundBlendMode: 'multiply',
          },
        }}
      >
        <Title />
        <MenuItems />
      </MUIDrawer>
      <MUIDrawer
        variant="permanent"
        sx={{
          position: 'relative',
          zIndex: 1,
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            backgroundColor: 'primary.main',
            backgroundImage: `url(${orangeRouteUrl})`,
            backgroundBlendMode: 'multiply',
            p: 3,
            overflow: 'hidden',
          },
        }}
        open
      >
        <Title />
        <MenuItems />
        <Box sx={{ flex: 1 }} />
        <ProfileMenu />
      </MUIDrawer>
    </Box>
  )
}

export default Drawer
