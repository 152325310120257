import { get } from 'lodash'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FormHelperText, Typography } from '@mui/material'
import UploadFile from 'components/atoms/UploadFile'
import { useTranslation } from 'react-i18next'

function ControlledUploadFile({ name, label }) {
  const { t } = useTranslation()
  const formContext = useFormContext()
  const controlFromContext = get(formContext, 'control')
  const error = get(formContext, `formState.errors.${name}`)
  return (
    <>
      <Typography variant="body2">{label}</Typography>
      <Controller
        name={name}
        control={controlFromContext}
        render={({ field }) => (
          <UploadFile value={field.value} onChange={field.onChange} />
        )}
      />
      {error && (
        <FormHelperText
          sx={{ color: 'error.main', ml: (theme) => theme.spacing(1.75) }}
        >
          {get(error, 'message', t('invalid-field'))}
        </FormHelperText>
      )}
    </>
  )
}

export default ControlledUploadFile
