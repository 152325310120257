/* eslint-disable react/jsx-props-no-spreading */
import { Box, Button } from '@mui/material'
import ControlledSwitch from 'components/molecules/ControlledSwitch'
import ControlledTextField from 'components/molecules/ControlledTextField'
import ControlledUploadFile from 'components/molecules/ControlledUploadFile'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Props } from './types'

function Form({ onSubmit, variant }: Props) {
  const { t } = useTranslation()

  return (
    <Box
      component="form"
      onSubmit={onSubmit}
      sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
    >
      <ControlledTextField name="name" label={t('name')} />
      <ControlledTextField
        name="description"
        label={t('description')}
        multiline
        minRows={4}
      />
      <ControlledTextField name="author" label={t('author')} />
      <ControlledUploadFile name="picture" label={t('picture')} />
      <ControlledSwitch name="isPrincipal" label={t('is-principal')} />
      <ControlledTextField
        type="number"
        name="distance"
        label={t('distance')}
      />
      <ControlledTextField
        type="number"
        name="duration"
        label={t('duration')}
      />
      <Button variant="contained" color="secondary" type="submit">
        {variant === 'create' ? t('common-save') : t('common-update')}
      </Button>
    </Box>
  )
}

export default Form
