export const USER_ME = '/users/me'
export const USERS = '/users'
export const USER = '/users/:id'
export const AUTHENTICATE = '/users/authenticate'
export const REFRESH_TOKEN = '/users/refresh-token'
export const REVOKE_TOKEN = '/users/revoke-token'
export const RESET_PASSWORD = '/users/reset-forgot-password'
export const FORGOT_PASSWORD = '/users/forgot-password'
export const EXPORT_DATA = '/users/export'
export const AGE_RANGES = '/users/age-ranges'
