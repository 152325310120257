import { Add } from '@mui/icons-material'
import { Box, Fab, Typography } from '@mui/material'
import { useThematicLevelMissionsQuery } from 'domains/thematics/queries'
import { max, orderBy, times } from 'lodash'
import React, { useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import MissionBlockSkeleton from '../MissionBlock/Skeleton'
import MissionBlockButton from '../MissionBlockButton'
import MissionForm from '../MissionForm'
import useCreateMissionForm from '../MissionForm/forms/create'
import { Props } from './types'

function MissionsList({ thematicId, levelId }: Props) {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const { data: missions = [], isLoading } = useThematicLevelMissionsQuery(
    { id: thematicId, levelId },
    { enabled: !!thematicId, levelId },
  )
  const nextIndex =
    missions?.length > 0
      ? max<number>(missions.map(({ index }) => index)) + 1
      : 1
  const form = useCreateMissionForm(nextIndex)

  const handleCreate = () => {
    setOpen(true)
  }

  return (
    <Box sx={{ mt: 4 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ flex: 1 }} variant="h4">
          {t('missions')}
        </Typography>
        <Fab size="small" onClick={handleCreate}>
          <Add />
        </Fab>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          mt: 2,
          overflow: 'auto',
        }}
      >
        {isLoading
          ? times(4, (i) => <MissionBlockSkeleton key={i} />)
          : orderBy(missions, ['index']).map((mission, i) => (
              <MissionBlockButton
                key={mission.id}
                thematicId={thematicId}
                levelId={levelId}
                mission={mission}
                missions={missions}
                index={mission?.index}
              />
            ))}
      </Box>
      <FormProvider {...form}>
        <MissionForm
          variant="create"
          open={open}
          setOpen={setOpen}
          thematicId={thematicId}
          levelId={levelId}
        />
      </FormProvider>
    </Box>
  )
}

export default MissionsList
