import { MenuItem } from '@mui/material'
import ControlledSelect from 'components/molecules/ControlledSelect'
import { first, get, map } from 'lodash'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { getEligibleOperators } from '../../helpers'
import useGridColumns from '../../hooks/useGridColumns'

function ToolbarFilterRowOperators({ index }) {
  const { t } = useTranslation()
  const {
    control,
    setValue,
    watch,
    formState: { isDirty },
  } = useFormContext()
  const columns = useGridColumns()
  const field = watch(`filters.${index}.field`)
  const column = get(columns, field)

  const fieldType = column.type
  const operators = getEligibleOperators(fieldType, t)

  useEffect(() => {
    if (isDirty) {
      setValue(`filters.${index}.op`, first(operators)?.value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field])

  return (
    <ControlledSelect
      sx={{ width: 200 }}
      name={`filters.${index}.op`}
      control={control}
      label={t('operator')}
    >
      {map(operators, ({ value, label }) => (
        <MenuItem key={value} value={value}>
          {label}
        </MenuItem>
      ))}
    </ControlledSelect>
  )
}

export default ToolbarFilterRowOperators
