import { yupResolver } from '@hookform/resolvers/yup'
import { SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import {
  useCreateOfferTypeMutation,
  useUpdateOfferTypeMutation,
} from 'domains/offer-types/mutations'
import { OFFER_TYPES } from 'domains/offer-types/templates'
import { isEmpty } from 'lodash'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import * as Yup from 'yup'

function useOfferTypeForm(offerTypeId) {
  const { t } = useTranslation()
  const isCreation = isEmpty(offerTypeId)
  const { popSnackbar } = useSnackbar()
  const createOfferType = useCreateOfferTypeMutation()
  const updateOfferType = useUpdateOfferTypeMutation()
  const queryClient = useQueryClient()

  const defaultValues = {
    name: '',
    description: '',
    defaultMonthDuration: '',
    code: '',
    dashboardAccess: false,
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('required-field')),
    description: Yup.string().required(t('required-field')),
    defaultMonthDuration: Yup.number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .nullable()
      .required(t('required-field'))
      .min(1, t('too-little')),
    code: Yup.string().required(t('required-field')),
    dashboardAccess: Yup.bool().required(t('required-field')),
  })

  const form = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = async (values) => {
    if (isCreation) {
      await createOfferType.mutateAsync({ data: values })
      popSnackbar(t('offer-type-create-success'), SUCCESS)
      form.reset(defaultValues, { keepIsSubmitted: false })
      queryClient.invalidateQueries({ queryKey: [OFFER_TYPES] })
    } else {
      await updateOfferType.mutateAsync({ id: offerTypeId, data: values })
      popSnackbar(t('offer-type-update-success'), SUCCESS)
      form.reset(defaultValues, { keepIsSubmitted: false })
      queryClient.invalidateQueries({ queryKey: [OFFER_TYPES] })
    }
  }

  return { form, onSubmit }
}

export default useOfferTypeForm
