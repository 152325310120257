import { yupResolver } from '@hookform/resolvers/yup'
import { SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import {
  useCreateAvailabilityMutation,
  useUpdateAvailabilityMutation,
} from 'domains/availabilities/mutation'
import { useGetAvailabilityQuery } from 'domains/availabilities/queries'
import { AVAILABILITIES } from 'domains/availabilities/templates'
import { isEmpty } from 'lodash'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import * as Yup from 'yup'

function useAvailabilityForm({ id, handleDialog }) {
  const isCreation = isEmpty(id)
  const { popSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const editAvaialbility = useUpdateAvailabilityMutation()
  const createAvaialbility = useCreateAvailabilityMutation()
  const queryClient = useQueryClient()
  const { data: availability } = useGetAvailabilityQuery(
    { id },
    { enabled: !!id },
  )

  const defaultValues = {
    name: '',
    description: '',
    minTime: 0,
    maxTime: 0,
    picture: null,
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('required-field')),
  })

  const form = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  useEffect(() => {
    if (availability) {
      form.reset(availability)
    } else {
      form.reset(defaultValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availability])

  const onSubmit = async (values) => {
    try {
      if (isCreation) {
        await createAvaialbility.mutateAsync({ data: values })
        popSnackbar(t('availability-create-success'), SUCCESS)
      } else {
        await editAvaialbility.mutateAsync({ id, data: values })
        popSnackbar(t('availability-edit-success'), SUCCESS)
      }
      queryClient.invalidateQueries(AVAILABILITIES)
      handleDialog()
    } catch {
      form.reset(values)
    }
  }

  return { onSubmit, form }
}

export default useAvailabilityForm
