import { Add } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import { max } from 'lodash'
import React, { useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { MAX_SIZE } from '../../enums'
import LevelForm from '../LevelForm'
import useCreateThematicLevelForm from '../LevelForm/forms/create'

function AddLevelButton({ thematic, setSelected }) {
  const [open, setOpen] = useState(false)
  const nextIndex = max<number>(thematic.levels.map(({ index }) => index)) + 1

  const form = useCreateThematicLevelForm(nextIndex)

  const handleCreate = () => {
    setOpen(true)
  }

  return (
    <>
      <IconButton
        onClick={handleCreate}
        sx={{
          height: 70,
          width: 70,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Add
          sx={{
            borderRadius: '50%',
            bgcolor: 'primary.main',
            color: 'text.primary',
            border: (theme) => `3px solid ${theme.palette.primary.main}`,
            height: MAX_SIZE,
            width: MAX_SIZE,
          }}
        />
      </IconButton>
      <FormProvider {...form}>
        <LevelForm
          thematicId={thematic.id}
          open={open}
          setOpen={setOpen}
          variant="create"
          onSuccess={(level) => setSelected(level.id)}
        />
      </FormProvider>
    </>
  )
}

export default AddLevelButton
