import { Add } from '@mui/icons-material'
import { alpha, Box, Grid, IconButton, Typography } from '@mui/material'
import { usePartnersQuery } from 'domains/partners/queries'
import { filter, find } from 'lodash'
import React, { useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Carousel from 'react-material-ui-carousel'
import { ivory } from 'config/theme/palette'
import CustomerPartnersForm from '../CustomerPartnersForm'
import useAddCustomerPartnersForm from '../CustomerPartnersForm/forms/add'
import PartnerLogo from '../PartnerLogo'
import { Props } from './types'

function CustomerCardPartners({ customer }: Props) {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()
  const form = useAddCustomerPartnersForm()
  const { data: partners } = usePartnersQuery()

  const partnersNotAlreadySelected = filter(
    partners?.data ?? [],
    (partner) => !find(customer.partners, ['id', partner.id]),
  )

  const handleAddPartner = () => setOpen(true)

  const sliderItems: number =
    customer?.partners.length > 3 ? 3 : customer?.partners.length
  const carouselItems: Array<any> = []

  for (let i = 0; i < customer?.partners.length; i += sliderItems) {
    if (i % sliderItems === 0) {
      carouselItems.push(
        <Grid key={i} container spacing={0} sx={{ mt: 1 }}>
          {customer?.partners
            ?.slice(i, i + sliderItems)
            .map((partner, index) => {
              return (
                <PartnerLogo
                  key={partner.id ?? index}
                  customer={customer}
                  partner={partner}
                />
              )
            })}
        </Grid>,
      )
    }
  }

  return (
    <Box sx={{ width: (theme) => theme.spacing(24) }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          color="text.secondary"
          sx={{
            width: 'fit-content',
            pr: 2,
            borderBottom: (theme) =>
              `1px solid ${theme.palette.text.secondary}`,
          }}
        >
          {t('partners')}
        </Typography>
        {partnersNotAlreadySelected.length > 0 && (
          <IconButton
            size="small"
            onClick={handleAddPartner}
            sx={{
              mr: 1,
              width: (theme) => theme.spacing(3),
              height: (theme) => theme.spacing(3),
              border: (theme) => `3px solid ${theme.palette.common.white}`,
              '&:hover': {
                transform: 'scale(1.1)',
              },
            }}
          >
            <Add sx={{ color: 'background.default' }} />
          </IconButton>
        )}
      </Box>
      <Box
        sx={{
          mt: 2.5,
          display: 'flex',
          gap: 2,
          flexWrap: 'wrap',
          alignItems: 'center',
          minHeight: 57,
        }}
      >
        <Carousel
          sx={{ width: '100%' }}
          navButtonsAlwaysInvisible
          indicatorIconButtonProps={{
            style: {
              color: alpha(ivory, 0.4), // 3
            },
          }}
          activeIndicatorIconButtonProps={{
            style: {
              color: ivory, // 2
            },
          }}
        >
          {carouselItems}
        </Carousel>
      </Box>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...form}>
        <CustomerPartnersForm
          open={open}
          setOpen={setOpen}
          customer={customer}
        />
      </FormProvider>
    </Box>
  )
}

export default CustomerCardPartners
