import { AddCircleOutline, Search } from '@mui/icons-material'
import { Grid, TextField } from '@mui/material'
import CardButton from 'components/atoms/CardButton'
import Header from 'components/molecules/Header'
import { useThematicsQuery } from 'domains/thematics/queries'
import { THEMATICS_CREATE_PATH } from 'enums/paths'
import { debounce, deburr, filter, lowerCase, times } from 'lodash'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ThematicCard from './components/ThematicCard'
import { MIN_HEIGHT_THEMATIC_CARD } from './components/ThematicCard/constants'
import ThematicCardSkeleton from './components/ThematicCard/Skeleton'

function ThematicsList() {
  const { t } = useTranslation()
  const [search, setSearch] = useState('')
  const { data: thematics, isLoading } = useThematicsQuery()
  const navigate = useNavigate()
  const filteredThematics = thematics?.data
    ? filter(thematics?.data, (thematic) =>
        lowerCase(deburr(`${thematic.name} ${thematic.description}`)).includes(
          lowerCase(search),
        ),
      )
    : []
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const deboucedFilterThematics = useCallback(
    debounce((_search) => {
      setSearch(_search)
    }, 300),
    [],
  )

  return (
    <>
      <Header
        title={t('thematics')}
        actions={[
          <TextField
            key={0}
            onChange={(e) => deboucedFilterThematics(e.target.value)}
            size="small"
            InputProps={{
              endAdornment: <Search />,
            }}
          />,
        ]}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3} lg={2}>
          <CardButton
            Icon={AddCircleOutline}
            text="add-thematic"
            clickAction={() => navigate(THEMATICS_CREATE_PATH)}
            minHeight={MIN_HEIGHT_THEMATIC_CARD}
          />
        </Grid>
        {isLoading
          ? times(4, (i) => (
              <Grid key={i} item xs={12} sm={6} md={3} lg={2}>
                <ThematicCardSkeleton />
              </Grid>
            ))
          : filteredThematics.map((thematic) => (
              <Grid key={thematic.id} item xs={12} sm={6} md={3} lg={2}>
                <ThematicCard thematic={thematic} />
              </Grid>
            ))}
      </Grid>
    </>
  )
}

export default ThematicsList
