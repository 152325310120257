import { useQueryWrapper } from 'domains/helpers'
import { PaginatedType } from 'domains/types'
import { EDUCATIONAL_LEVEL, EDUCATIONAL_LEVELS } from './templates'
import { EducationalLevel } from './types'

export function useEducationalLevelsQuery(
  params?,
  options?,
  filtersPaginationAndSort?,
) {
  return useQueryWrapper<PaginatedType<EducationalLevel>>(
    'GET',
    EDUCATIONAL_LEVELS,
    params,
    options,
    filtersPaginationAndSort,
  )
}

export function useEducationalLevelQuery(params?, options?) {
  return useQueryWrapper<EducationalLevel>(
    'GET',
    EDUCATIONAL_LEVEL,
    params,
    options,
  )
}
