import { Button, Paper, Typography } from '@mui/material'
import ControlledTextField from 'components/molecules/ControlledTextField'
import { ERROR, useSnackbar } from 'contexts/SnackbarContext'
import { useForgotPasswordMutation } from 'domains/users/mutations'
import { FORGOT_PASSWORD_SUCCESS_PATH } from 'enums/paths'
import React from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useForgotPasswordForm from './form'

function ForgotPasswordForm() {
  const { t } = useTranslation()
  const form = useForgotPasswordForm()
  const forgotPassword = useForgotPasswordMutation()
  const navigate = useNavigate()
  const { popSnackbar } = useSnackbar()

  const onSubmit = async (values) => {
    forgotPassword.mutate(
      { data: values },
      {
        onSuccess: () => {
          navigate(FORGOT_PASSWORD_SUCCESS_PATH)
        },
        onError: () => {
          popSnackbar(t('error'), ERROR)
        },
      },
    )
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...form}>
      <Paper
        component="form"
        onSubmit={form.handleSubmit(onSubmit)}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          padding: 2,
          width: 400,
        }}
      >
        <Typography
          variant="h4"
          className="animate__animated animate__headShake"
          color="primary"
          fontSize="large"
          sx={{ alignSelf: 'center' }}
        >
          {t('forgot-password')}
        </Typography>
        <ControlledTextField name="email" label={t('email')} />
        <Button color="primary" type="submit">
          {t('reset-password')}
        </Button>
      </Paper>
    </FormProvider>
  )
}

export default ForgotPasswordForm
