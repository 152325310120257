/* eslint-disable react/jsx-props-no-spreading */
import { TabContext, TabPanel } from '@mui/lab'
import { Box, Grid, Tab, Tabs } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import EducationLevelManager from './components/EducationLevel'
import GenderManager from './components/Gender'
import OfferTypeManager from './components/OfferType'
import TownCategoryManager from './components/TownCategory'

function UserParamsManager() {
  const { t } = useTranslation()
  const [selectedTab, setSelectedTab] = useState('0')

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue)
  }

  return (
    <TabContext value={selectedTab}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={selectedTab} onChange={handleTabChange}>
          <Tab
            sx={{ color: (theme) => theme.palette.common.black }}
            label={t('gender')}
            value="0"
          />
          <Tab
            sx={{ color: (theme) => theme.palette.common.black }}
            label={t('educational-level')}
            value="1"
          />
          <Tab
            sx={{ color: (theme) => theme.palette.common.black }}
            label={t('offer-types')}
            value="2"
          />
          <Tab
            sx={{ color: (theme) => theme.palette.common.black }}
            label={t('town-categories')}
            value="3"
          />
        </Tabs>
      </Box>
      <TabPanel value="0">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <GenderManager />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value="1">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <EducationLevelManager />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value="2">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <OfferTypeManager />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value="3">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TownCategoryManager />
          </Grid>
        </Grid>
      </TabPanel>
    </TabContext>
  )
}

export default UserParamsManager
