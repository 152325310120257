import L from 'leaflet'
import stepMarker from 'assets/svg/stepMarker.svg'
import endFlag from 'assets/png/end-flag.png'
import startFlag from 'assets/png/start-flag.png'

const stepIcon = new L.Icon({
  iconUrl: stepMarker,
  iconRetinaUrl: stepMarker,
  popupAnchor: [0, 0],
  iconSize: [32, 45],
})

const startIcon = new L.Icon({
  iconUrl: startFlag,
  iconRetinaUrl: startFlag,
  popupAnchor: [0, 0],
  iconSize: [32, 32],
})

const endIcon = new L.Icon({
  iconUrl: endFlag,
  iconRetinaUrl: endFlag,
  popupAnchor: [0, 0],
  iconSize: [32, 32],
})

export { stepIcon, endIcon, startIcon }
