import { yupResolver } from '@hookform/resolvers/yup'
import { upperCase } from 'lodash'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

function useCreateFusionFieldForm() {
  const { t } = useTranslation()
  const defaultValues = {
    key: '',
    value: '',
    description: '',
    isDynamic: false,
  }

  const validationSchema = Yup.object().shape({
    key: Yup.string()
      .min(3, t('min-3-char'))
      .required(t('required-field'))
      .transform((prev) => upperCase(prev)),
    value: Yup.string(),
    description: Yup.string(),
    isDynamic: Yup.bool(),
  })

  return useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })
}

export default useCreateFusionFieldForm
