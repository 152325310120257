import { isEmpty } from 'lodash'

type EntityWithNameAndId = {
  id: string
  name: string
}

export function formatEntityWithNameAndIdToAutocomplete<
  T extends EntityWithNameAndId,
>(collection: T[]) {
  const collectionFormattedTemp = []
  if (!isEmpty(collection)) {
    collection.map((element) =>
      collectionFormattedTemp.push({ label: element?.name, id: element?.id }),
    )
  }
  return collectionFormattedTemp
}
