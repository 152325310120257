import { Add } from '@mui/icons-material'
import { Box, IconButton } from '@mui/material'
import DataGrid from 'components/molecules/DataGrid'
import { getEditedPair } from 'components/molecules/DataGrid/helpers'
import { ERROR, useSnackbar } from 'contexts/SnackbarContext'
import { usePatchAppParameterMutation } from 'domains/app-parameters/mutation'
import { APP_PARAMETERS } from 'domains/app-parameters/templates'
import { useGetAvailabilitiesQuery } from 'domains/availabilities/queries'
import { replaceOp } from 'helpers/jsonPatch'
import { get } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import AvailabilityForm from '../AvailabilitiesForm'
import useAvailabilitiesGrid from './grid'

function AvailabilitiesManager() {
  const [openModal, setOpenModal] = useState(false)
  const [selectedId, setSelectedId] = useState(null)

  const handleOpenModal = (id = null) => {
    if (id) {
      setSelectedId(id)
    }
    if (openModal) {
      setSelectedId(null)
    }
    setOpenModal(!openModal)
  }

  const { columns } = useAvailabilitiesGrid({ handleOpenModal })
  const patchAppParam = usePatchAppParameterMutation()
  const queryClient = useQueryClient()
  const { popSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const handleRowUpdate = async (next, prev) => {
    const { id } = prev
    const editedPair = getEditedPair(next, prev)

    if (!editedPair) {
      return prev
    }

    const { field, value } = editedPair

    try {
      await patchAppParam.mutateAsync({
        id,
        data: [replaceOp(field, value)],
      })
      queryClient.invalidateQueries({
        queryKey: [APP_PARAMETERS],
      })
      return next
    } catch (error) {
      const message = get(error, `response.data.${field}[0]`, t('error'))
      popSnackbar(message, ERROR)
      return prev
    }
  }

  const handleProcessRowUpdateError = React.useCallback((error: Error) => {
    popSnackbar(error.message, ERROR)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <DataGrid
        id="availabilities-manager"
        getEntities={useGetAvailabilitiesQuery}
        sx={{ flex: 1 }}
        columns={columns}
        processRowUpdate={handleRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        additionalActions={[
          <IconButton
            sx={{ bgcolor: 'primary.main' }}
            onClick={() => handleOpenModal()}
          >
            <Add />
          </IconButton>,
        ]}
      />

      <AvailabilityForm
        open={openModal}
        handleDialog={handleOpenModal}
        selectedId={selectedId}
      />
    </Box>
  )
}

export default AvailabilitiesManager
