import { KeyboardArrowLeft } from '@mui/icons-material'
import { Box, IconButton, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { HeaderProps } from './types'

function Header({
  backButton = false,
  title,
  actions = [],
  backButtonUrl = '',
}: HeaderProps) {
  return (
    <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
      {backButton && (
        <IconButton component={Link} to={backButtonUrl}>
          <KeyboardArrowLeft
            sx={{ height: 40, width: 40, color: 'text.primary' }}
          />
        </IconButton>
      )}
      <Typography
        variant="h1"
        color="secondary.main"
        sx={{
          borderBottom: '2px solid',
          borderBottomColor: 'secondary.main',
          width: 'fit-content',
          pr: 6,
          pb: 1,
          mb: 2,
        }}
      >
        {title}
      </Typography>
      <Box sx={{ flex: 1 }} />
      {actions}
    </Box>
  )
}

export default Header
