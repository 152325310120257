import { CheckCircle } from '@mui/icons-material'
import { Paper, Typography } from '@mui/material'
import LinkButton from 'components/atoms/LinkButton'
import { LOGIN_PATH } from 'enums/paths'
import React from 'react'
import { useTranslation } from 'react-i18next'

function ForgotPasswordSuccess() {
  const { t } = useTranslation()

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        padding: 2,
      }}
    >
      <CheckCircle
        className="animate__animated animate__headShake"
        color="primary"
        fontSize="large"
        sx={{ alignSelf: 'center' }}
      />
      <Typography variant="body1">{t('forgot-password-success')}</Typography>
      <LinkButton to={LOGIN_PATH}>{t('back-to-connexion')}</LinkButton>
    </Paper>
  )
}

export default ForgotPasswordSuccess
