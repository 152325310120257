import { Delete, Edit } from '@mui/icons-material'
import { Box, Fab, Typography } from '@mui/material'
import MissionsList from 'components/organisms/LevelsManager/components/MissionsList'
import { useConfirm } from 'contexts/ConfirmContext'
import { SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { useLevelQuery } from 'domains/levels/queries'
import { useDeleteThematicLevelMutation } from 'domains/thematics/mutations'
import { THEMATIC } from 'domains/thematics/templates'
import React, { useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import LevelForm from '../LevelForm'
import useEditThematicLevelForm from '../LevelForm/forms/edit'
import { Props } from './types'

function Level({ thematicId, setSelected, id }: Props) {
  const { t } = useTranslation()
  const { popSnackbar } = useSnackbar()
  const [open, setOpen] = useState(false)
  const { confirm } = useConfirm()
  const { data: level, isLoading } = useLevelQuery({ id }, { enabled: !!id })
  const form = useEditThematicLevelForm(level)
  const deleteLevel = useDeleteThematicLevelMutation()
  const queryClient = useQueryClient()

  const handleEdit = () => {
    setOpen(true)
  }

  const handleDelete = () => {
    confirm(t('level-delete-confirm'), t('irreversible-action')).then(() =>
      deleteLevel.mutate(
        { id: thematicId, levelId: id },
        {
          onSuccess: () => {
            popSnackbar(t('level-delete-success'), SUCCESS)
            queryClient.invalidateQueries([THEMATIC, { id: thematicId }])
            setSelected(null)
          },
        },
      ),
    )
  }

  return (
    !isLoading &&
    level && (
      <>
        <Box
          sx={{
            flex: 1,
            p: 1,
            height: '100%',
            overflow: 'auto',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: 1 }}>
            <Typography variant="h3" sx={{ flex: 1, fontWeight: 'bold' }}>
              {level.name}
            </Typography>
            <Fab size="small" onClick={handleDelete}>
              <Delete />
            </Fab>
            <Fab size="small" onClick={handleEdit}>
              <Edit />
            </Fab>
          </Box>
          <Typography variant="body1">{level.description}</Typography>
          <MissionsList thematicId={thematicId} levelId={id} />
        </Box>
        <FormProvider {...form}>
          <LevelForm
            thematicId={thematicId}
            open={open}
            setOpen={setOpen}
            variant="edit"
          />
        </FormProvider>
      </>
    )
  )
}

export default Level
