import { Operators } from 'enums/filters'
import { isEmpty } from 'lodash'

export const filterUserRuns = (isSupervisorOrAdmin, isCustomer, user) => {
  if (isSupervisorOrAdmin) {
    return []
  }
  if (isCustomer && !isEmpty(user?.customer)) {
    return [
      {
        field: 'run',
        filterKey: 'run.customer.id',
        op: Operators.EQUAL,
        value: user?.customer.id,
        multipleValue: [],
      },
    ]
  }
  return [
    {
      field: 'user',
      filterKey: 'user.id',
      op: Operators.EQUAL,
      value: user?.id,
      multipleValue: [],
    },
  ]
}
