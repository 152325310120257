import { useMutationWrapper } from 'domains/helpers'
import { MAIL_FUSION_FIELD, MAIL_FUSION_FIELDS } from './templates'

export function useCreateMailFusionFieldMutation(options?) {
  return useMutationWrapper('POST', MAIL_FUSION_FIELDS, options)
}

export function usePatchMailFusionFieldMutation(options?) {
  return useMutationWrapper('PATCH', MAIL_FUSION_FIELD, options)
}

export function useDeleteMailFusionFieldMutation(options?) {
  return useMutationWrapper('DELETE', MAIL_FUSION_FIELD, options)
}
