import { Operators } from 'enums/filters'
import { isEmpty } from 'lodash'

export const isErrorFilters = (filters, form, t) => {
  form.clearErrors()
  let isError = false
  filters.forEach((f, index) => {
    if (
      isEmpty(f.value) &&
      ![
        Operators.NULL,
        Operators.NOT_NULL,
        Operators.IN,
        Operators.NOT_IN,
      ].includes(f.op)
    ) {
      form.setError(`filters.${index}.value`, {
        type: 'custom',
        message: t('required-field'),
      })
      isError = true
    }
    if (
      isEmpty(f.multipleValue) &&
      [Operators.IN, Operators.NOT_IN].includes(f.op)
    ) {
      form.setError(`filters.${index}.multipleValue`, {
        type: 'custom',
        message: t('required-field'),
      })
      isError = true
    }
    return isError
  })
  return isError
}
