import { useMutationWrapper } from 'domains/helpers'
import { RUNS, RUN } from './templates'

export function useCreateRunMutation(options?) {
  return useMutationWrapper('POST', RUNS, options)
}

export function useUpdateRunMutation(options?) {
  return useMutationWrapper('PUT', RUN, options)
}

export function useDeleteRunMutation(options?) {
  return useMutationWrapper('DELETE', RUN, options)
}
