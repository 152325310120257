import { yupResolver } from '@hookform/resolvers/yup'
import { SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import {
  useCreateEducationalLevelMutation,
  useUpdateEducationalLevelMutation,
} from 'domains/educationalLevels/mutations'
import { EDUCATIONAL_LEVELS } from 'domains/educationalLevels/templates'
import { isEmpty } from 'lodash'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import * as Yup from 'yup'

function useEducationalLevelForm(educationId) {
  const { t } = useTranslation()
  const isCreation = isEmpty(educationId)
  const { popSnackbar } = useSnackbar()
  const createEducation = useCreateEducationalLevelMutation()
  const updateEducation = useUpdateEducationalLevelMutation()
  const queryClient = useQueryClient()

  const defaultValues = {
    name: '',
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('required-field')),
  })

  const form = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  const onSubmit = async (values) => {
    if (isCreation) {
      await createEducation.mutateAsync({ data: values })
      popSnackbar(t('user-education-create-success'), SUCCESS)
      form.reset(defaultValues, { keepIsSubmitted: false })
      queryClient.invalidateQueries({ queryKey: [EDUCATIONAL_LEVELS] })
    } else {
      await updateEducation.mutateAsync({ id: educationId, data: values })
      popSnackbar(t('education-update-success'), SUCCESS)
      form.reset(defaultValues, { keepIsSubmitted: false })
      queryClient.invalidateQueries({ queryKey: [EDUCATIONAL_LEVELS] })
    }
  }

  return { form, onSubmit }
}

export default useEducationalLevelForm
