import { useMutationWrapper } from 'domains/helpers'
import { APP_PARAMETER, APP_PARAMETERS } from './templates'

export function useUpdateAppParameterMutation(options?) {
  return useMutationWrapper('PUT', APP_PARAMETER, options)
}

export function usePatchAppParameterMutation(options?) {
  return useMutationWrapper('PATCH', APP_PARAMETER, options)
}

export function useDeleteAppParameterMutation(options?) {
  return useMutationWrapper('DELETE', APP_PARAMETER, options)
}

export function useCreateAppParameterMutation(options?) {
  return useMutationWrapper('POST', APP_PARAMETERS, options)
}
