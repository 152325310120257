/* eslint-disable no-param-reassign */
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { PRINT_LENGTH, PRINT_HEIGHT } from './constants'

export const printDocument = async (customer) => {
  const input = document.getElementById('dashboard')
  const canvas = await html2canvas(input, {
    scale: 2,
    onclone(document, element) {
      document.getElementById('dashboard').style.padding = '20px'
      const auto = document.getElementById('autocomplete')
      if (auto && !customer) {
        auto.parentElement.removeChild(auto)
      }
    },
  })
  const imgData = canvas.toDataURL('image/png')
  // eslint-disable-next-line new-cap
  const pdf = new jsPDF('l', 'mm', [PRINT_LENGTH, PRINT_HEIGHT])
  const width = pdf.internal.pageSize.getWidth()
  const height = pdf.internal.pageSize.getHeight()

  const widthRatio = canvas.width !== 0 ? width / canvas.width : 1
  const heightRatio = canvas.height !== 0 ? height / canvas.height : 1
  const ratio = widthRatio > heightRatio ? heightRatio : widthRatio
  pdf.addImage(
    imgData,
    'PNG',
    0,
    0,
    canvas.width * ratio,
    canvas.height * ratio,
  )
  pdf.save(
    customer?.name
      ? `Tableau de bord - ${customer?.name}.pdf`
      : `Tableau de bord.pdf`,
  )
}
