import { Delete, Edit } from '@mui/icons-material'
import { Box, IconButton, Paper, Tooltip, Typography } from '@mui/material'
import { useConfirm } from 'contexts/ConfirmContext'
import { useDeleteCustomerMutation } from 'domains/customers/mutations'
import { CUSTOMERS } from 'domains/customers/templates'
import { isEmpty } from 'lodash'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import CustomerCardPartners from '../CustomerCardPartners'
import CustomerForm from '../CustomerForm'
import useEditCustomerForm from '../CustomerForm/forms/edit'
import { MIN_HEIGHT_CUSTOMER_CARD } from '../CustomersList/enums'
import { Props } from './types'

function CustomerCard({ customer }: Props) {
  const { t } = useTranslation()
  const [formOpen, setFormOpen] = useState(false)
  const { confirm } = useConfirm()
  const deleteCustomer = useDeleteCustomerMutation()
  const queryClient = useQueryClient()
  const editForm = useEditCustomerForm(customer)

  const handleDelete = () => {
    confirm(t('confirm-customer-delete'))
      .then(() =>
        deleteCustomer.mutate(
          { id: customer.id },
          { onSuccess: () => queryClient.invalidateQueries(CUSTOMERS) },
        ),
      )
      .catch(() => null)
  }

  const handleEdit = () => setFormOpen(true)

  return (
    <Paper
      sx={{
        bgcolor: 'primary.main',
        p: 2,
        minHeight: MIN_HEIGHT_CUSTOMER_CARD,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          alignItems: 'flex-start',
          justifyContent: 'space-between',
        }}
      >
        <Tooltip title={customer.name}>
          <Box>
            <Typography
              variant="h5"
              sx={{
                color: 'text.secondary',
              }}
            >
              {customer.name}
            </Typography>
          </Box>
        </Tooltip>

        <Box sx={{ display: 'flex' }}>
          <IconButton size="small" onClick={handleEdit}>
            <Edit sx={{ color: 'text.secondary' }} />
          </IconButton>
          <IconButton size="small" onClick={handleDelete}>
            <Delete sx={{ color: 'text.secondary' }} />
          </IconButton>
        </Box>
      </Box>
      {!isEmpty(customer.customerOfferTypes) && (
        <Box sx={{ display: 'flex' }}>
          <Typography variant="body2">{t('your-active-offer')} :</Typography>
          {customer.customerOfferTypes
            .filter(
              (cot) =>
                cot.startValidityDate < DateTime.now().toISO() &&
                cot.endValidityDate > DateTime.now().toISO(),
            )
            .map((customerOffer, index, array) => (
              <Typography variant="body2" key={customerOffer.id}>{`"${
                customerOffer.offerType?.name
              }"${index + 1 === array.length ? '' : ', '}`}</Typography>
            ))}
        </Box>
      )}
      <Box sx={{ display: 'flex', alignItems: 'flex-start', mt: 2 }}>
        <Box
          component="img"
          src={customer.picture?.url}
          alt=""
          sx={{
            height: 110,
            width: 110,
            objectFit: 'cover',
            borderRadius: 1,
            bgcolor: 'background.default',
            mr: 1,
          }}
        />
        <CustomerCardPartners customer={customer} />
      </Box>
      <FormProvider {...editForm}>
        <CustomerForm open={formOpen} setOpen={setFormOpen} variant="edit" />
      </FormProvider>
    </Paper>
  )
}

export default CustomerCard
