import { Duration } from 'luxon'

export const formatDistanceToChip = (distance) => {
  return `${distance !== null ? distance : '?'} Km`
}

export const formatDurationToChip = (duration: number) => {
  if (duration === null) {
    return '??h??m'
  }

  const luxonDuration = Duration.fromObject({ minutes: duration })
    .shiftTo('hours', 'minutes')
    .toObject()

  return `${luxonDuration.hours}h${luxonDuration.minutes.toLocaleString(
    undefined,
    {
      minimumIntegerDigits: 2,
      useGrouping: false,
    },
  )}m`
}
