export const MAX_VALUE_AGE_RANGE_GRAPH = 90
export const Y_GRID_VALUES = [0, 25, 50, 75]
export const Y_TICK_ROTATION = 0
export const Y_TICK_PADDING = 10
export const Y_TICK_SIZE = 0
export const BOTTOM_TICK_SIZE = 5
export const BOTTOM_TICK_PADDING = 5
export const BOTTOM_TICK_ROTATION = 0
export const MARGIN_BOTTOM_GRAPH = 20
export const MARGIN_LEFT_GRAPH = 30
