import { useMutationWrapper } from 'domains/helpers'
import { STEPS, STEP } from './templates'

export function useCreateStepMutation(options?) {
  return useMutationWrapper('POST', STEPS, options)
}

export function useUpdateStepMutation(options?) {
  return useMutationWrapper('PUT', STEP, options)
}

export function useDeleteStepMutation(options?) {
  return useMutationWrapper('DELETE', STEP, options)
}
