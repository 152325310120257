export const downloadFile = (response) => {
  const { bytes, fileName, contentType } = response
  const a = document.createElement('a')
  a.href = `data:${contentType};base64,${bytes}`
  a.download = fileName
  a.onclick = () => {
    document.body.removeChild(a)
  }
  a.style.display = 'none'
  document.body.appendChild(a)
  a.click()
}
