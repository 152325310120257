import { Close } from '@mui/icons-material'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'
import {
  useCreateLevelDefaultMutation,
  useUpdateLevelDefaultMutation,
} from 'domains/level-defaults/mutations'
import { useLevelDefaultQuery } from 'domains/level-defaults/queries'
import { LEVEL_DEFAULTS } from 'domains/level-defaults/templates'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import Form from './components/Form'
import useCreateLevelDefaultForm, { defaultValues } from './form'

function LevelDefaultForm({ open, close, id, nextIndex }) {
  const { t } = useTranslation()
  const { data: levelDefault } = useLevelDefaultQuery(
    { id },
    { enabled: !isEmpty(id) },
  )
  const isEditForm = !isEmpty(id)
  const form = useCreateLevelDefaultForm(nextIndex)
  const createLevelDefault = useCreateLevelDefaultMutation()
  const updateLevelDefault = useUpdateLevelDefaultMutation()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (levelDefault) {
      form.reset(levelDefault)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelDefault])

  const handleClose = () => {
    const values = defaultValues(nextIndex)
    form.reset(values)
    close()
  }

  const onSubmit = (values) => {
    if (!isEditForm) {
      const dataToSubmit = { ...values, index: nextIndex }
      return createLevelDefault.mutateAsync({ data: dataToSubmit }).then(() => {
        handleClose()
        queryClient.invalidateQueries(LEVEL_DEFAULTS)
      })
    }
    return updateLevelDefault.mutateAsync({ id, data: values }).then(() => {
      handleClose()
      queryClient.invalidateQueries(LEVEL_DEFAULTS)
    })
  }

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      sx={{
        '& .MuiPaper-root': {
          bgcolor: 'secondary.main',
        },
      }}
    >
      <DialogTitle>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h2" sx={{ color: 'common.white' }}>
            {!isEditForm ? t('common-save') : t('common-update')}
          </Typography>
          <IconButton onClick={() => handleClose()}>
            <Close sx={{ color: 'common.white' }} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <FormProvider {...form}>
          <Form
            onSubmit={form.handleSubmit(onSubmit)}
            variant={isEditForm ? 'edit' : 'create'}
          />
        </FormProvider>
      </DialogContent>
    </Dialog>
  )
}

export default LevelDefaultForm
