import { GridSortModel } from '@mui/x-data-grid'
import { Operators } from 'enums/filters'
import { Filter } from 'types/filters'

const formatPropertyPathToArrayString = (propertyPath) =>
  propertyPath
    .split('.')
    .map((property) => `[${property}]`)
    .join('')

const formatMultiplePropertyPathToArrayString = (
  filterKey,
  op,
  values = [],
) => {
  return values
    .map(
      ({ value }, index) =>
        `filters${formatPropertyPathToArrayString(
          filterKey,
        )}[$${op}][${index}]=${value}`,
    )
    .join('&')
}

export const formatFiltersToQueryString = (filters: Filter[]) =>
  filters
    .map(({ filterKey, op, value, multipleValue }) => {
      if ([Operators.IN, Operators.NOT_IN].includes(op)) {
        return formatMultiplePropertyPathToArrayString(
          filterKey,
          op,
          multipleValue,
        )
      }
      let formattedValue = null
      if ([Operators.NULL, Operators.NOT_NULL].includes(op)) {
        formattedValue = true
      } else {
        formattedValue = value
      }
      const propertyPathAsArrayString =
        formatPropertyPathToArrayString(filterKey)
      return `filters${propertyPathAsArrayString}[$${op}]=${formattedValue}`
    })
    .join('&')

export const formatPaginationToQueryString = (page, pageSize) => {
  return `pagination[page]=${page}&pagination[pageSize]=${pageSize}`
}

export const formatSortingToQueryString = (sortModel: GridSortModel) =>
  sortModel
    .map(({ field, sort }, index) => `sort[${index}]=${field}%3A${sort}`)
    .join('&')
