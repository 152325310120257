import { useQueryWrapper } from 'domains/helpers'
import { PaginatedType } from 'domains/types'
import { GENDER, GENDERS } from './templates'
import { Gender } from './types'

export function useGendersQuery(params?, options?, filtersPaginationAndSort?) {
  return useQueryWrapper<PaginatedType<Gender>>(
    'GET',
    GENDERS,
    params,
    options,
    filtersPaginationAndSort,
  )
}

export function useGenderQuery(params?, options?) {
  return useQueryWrapper<Gender>('GET', GENDER, params, options)
}
