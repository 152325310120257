import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

function useCreateCustomerForm() {
  const { t } = useTranslation()

  const defaultValues = {
    name: '',
    thematics: [],
    picture: null,
    partners: [],
    populationNumber: null,
    organisationNumber: null,
    customerOfferTypes: [],
  }

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('required-field')),
    picture: Yup.object().required(t('required-field')).nullable(),
    populationNumber: Yup.number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .nullable()
      .min(0, t('too-little')),
    organisationNumber: Yup.number()
      .transform((value) => (Number.isNaN(value) ? undefined : value))
      .nullable()
      .min(0, t('too-little')),
    customerOfferTypes: Yup.array().of(
      Yup.object().shape({
        offerType: Yup.object().nullable().required(t('required-field')),
        startValidityDate: Yup.date().nullable().required(t('required-field')),
        monthDuration: Yup.number()
          .transform((value) => (Number.isNaN(value) ? undefined : value))
          .nullable()
          .required(t('required-field'))
          .min(1, t('too-little')),
      }),
    ),
  })

  return useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })
}

export default useCreateCustomerForm
