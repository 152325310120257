import { Divider, Grid, Typography } from '@mui/material'
import React from 'react'

function SubSectionTitle({ children, textAlign }) {
  return (
    <Grid container alignItems="center" spacing={3}>
      <Grid item xs={textAlign === 'left' ? 1 : true}>
        <Divider
          sx={{
            bgcolor: 'primary.main',
            borderRadius: 1,
            borderBottomWidth: 5,
          }}
        />
      </Grid>
      <Grid item>
        <Typography variant="h4" color="primary.main">
          {children}
        </Typography>
      </Grid>
      <Grid item xs={textAlign === 'right' ? 1 : true}>
        <Divider
          sx={{
            bgcolor: 'primary.main',
            borderRadius: 1,
            borderBottomWidth: 5,
          }}
        />
      </Grid>
    </Grid>
  )
}

export default SubSectionTitle
