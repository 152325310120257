import { Box, CircularProgress, Typography } from '@mui/material'
import { ReactComponent as LogoWithoutName } from 'assets/svg/logo-without-name.svg'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MIN_HEIGHT_CARD_CONTENT } from '../../constants'
import { FormatRank } from './helper'

function GlobalRanks({ statistic = null }) {
  const { t } = useTranslation()
  if (statistic == null) return <CircularProgress color="secondary" />
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: MIN_HEIGHT_CARD_CONTENT,
        }}
      >
        {!statistic?.globalStats && (
          <Box>
            {statistic?.townCategoryRank !== 0 ? (
              <Typography color="primary.dark" variant="h1">
                {FormatRank(statistic?.townCategoryRank)}
              </Typography>
            ) : (
              <Typography color="primary.dark" variant="h1">
                {t('no-renseigned-population')}
              </Typography>
            )}
          </Box>
        )}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{
            '& svg path': {
              fill: (theme) => theme.palette.primary.main,
            },
            '& svg': {
              height: (theme) => theme.spacing(4),
              width: (theme) => theme.spacing(4),
            },
            mr: 0.5,
          }}
        >
          <LogoWithoutName />
        </Box>
        <Typography color="text.secondary" sx={{ mt: -0.5 }}>
          {t('global-standing-france')}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: MIN_HEIGHT_CARD_CONTENT,
        }}
      >
        {!statistic.globalStats && (
          <Box>
            {statistic?.globalRank !== 0 ? (
              FormatRank(statistic?.globalRank)
            ) : (
              <Typography color="primary.dark" variant="h1">
                {t('no-renseigned-population')}
              </Typography>
            )}
          </Box>
        )}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{
            '& svg path': {
              fill: (theme) => theme.palette.primary.main,
            },
            '& svg': {
              height: (theme) => theme.spacing(4),
              width: (theme) => theme.spacing(4),
            },
            mr: 0.5,
          }}
        >
          <LogoWithoutName />
        </Box>
        <Typography color="text.secondary" sx={{ mt: -0.5 }}>
          {t('audience-from-territory')}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: MIN_HEIGHT_CARD_CONTENT,
        }}
      >
        {!statistic.globalStats && (
          <Typography color="text.secondary" variant="h1">
            {statistic?.audienceFromTerritory} %
          </Typography>
        )}
      </Box>
    </>
  )
}

export default GlobalRanks
