import { Close } from '@mui/icons-material'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material'
import { isEmpty } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import TownCategoryForm from '../Form'

function TownCategoryDialog(props) {
  const { open, onClose, townCategoryId } = props
  const isCreation = isEmpty(townCategoryId)
  const { t } = useTranslation()

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {isCreation ? t('add-category') : t('update-category')}
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ '&.MuiDialogContent-root': { pt: 1 } }}>
        <TownCategoryForm onClose={onClose} townCategoryId={townCategoryId} />
      </DialogContent>
    </Dialog>
  )
}
export default TownCategoryDialog
