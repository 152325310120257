import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import ControlledTextField from 'components/molecules/ControlledTextField'
import ControlledUploadFile from 'components/molecules/ControlledUploadFile'
import { SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { CUSTOMERS } from 'domains/customers/templates'
import {
  useCreatePartnerMutation,
  useUpdatePartnerMutation,
} from 'domains/partners/mutations'
import { PARTNERS } from 'domains/partners/templates'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Props } from './types'

function PartnerForm({ open, setOpen, variant, partner = null }: Props) {
  const form = useFormContext()
  const { popSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const handleClose = () => setOpen(false)
  const createPartner = useCreatePartnerMutation()
  const editPartner = useUpdatePartnerMutation()
  const queryClient = useQueryClient()

  const onSubmit = async (values) => {
    if (variant === 'create') {
      return createPartner.mutateAsync({ data: values }).then(() => {
        setOpen(false)
        popSnackbar(t('partner-create-success'), SUCCESS)
        queryClient.invalidateQueries(PARTNERS)
        form.reset()
      })
    }

    if (variant === 'edit') {
      return editPartner
        .mutateAsync({ id: values.id, data: values })
        .then(() => {
          setOpen(false)
          popSnackbar(t('partner-edit-success'), SUCCESS)
          queryClient.invalidateQueries(PARTNERS)
          queryClient.invalidateQueries(CUSTOMERS)
        })
    }

    return null
  }

  return (
    <Dialog open={open} maxWidth="xs" fullWidth onClose={handleClose}>
      <Box component="form" onSubmit={form.handleSubmit(onSubmit)}>
        <DialogTitle>
          {variant === 'create' ? t('partner-create') : t('partner-edit')}
        </DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <ControlledTextField fullWidth name="name" label={t('name')} />
          <ControlledUploadFile name="logo" label={t('logo')} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('cancel')}</Button>
          <Button variant="contained" type="submit">
            {variant === 'create' ? t('create') : t('edit')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default PartnerForm
