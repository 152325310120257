import axios, { AxiosRequestConfig } from 'axios'
import { compact, isEmpty, merge } from 'lodash'
import { useMutation, useQuery } from 'react-query'
import urlcat from 'urlcat'
import { HttpMethod, MutationFunctionVariables } from './types'

export const getJwtToken = () => {
  return localStorage.getItem('token')
}

export const setJwtToken = (value: string) => {
  return localStorage.setItem('token', value)
}

export const clearJwtToken = () => {
  return localStorage.removeItem('token')
}

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000,
})

export const fetcher = async (
  method: HttpMethod,
  url: string,
  data?: any,
  axiosOptions?: AxiosRequestConfig,
  instance = axiosInstance,
) => {
  const token = localStorage.getItem('token')
  const res = await instance(
    merge(
      {
        method,
        url,
      },
      token
        ? {
            withCredentials: true,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        : {},
      { data },
      axiosOptions,
    ),
  )
  return res ? res.data : null
}

export function useQueryWrapper<T>(
  method,
  urlTemplate,
  params = {},
  options = {},
  filtersPaginationAndSort = null,
) {
  let url = urlTemplate

  try {
    url = urlcat(urlTemplate, params)
    if (!isEmpty(filtersPaginationAndSort?.filters)) {
      if (isEmpty(params)) {
        url = `${url}?${filtersPaginationAndSort.filters}`
      } else {
        url = `${url}&${filtersPaginationAndSort.filters}`
      }
    }
    if (!isEmpty(filtersPaginationAndSort?.pagination)) {
      if (isEmpty(params) && isEmpty(filtersPaginationAndSort?.filters)) {
        url = `${url}?${filtersPaginationAndSort.pagination}`
      } else {
        url = `${url}&${filtersPaginationAndSort.pagination}`
      }
    }
    if (!isEmpty(filtersPaginationAndSort?.sort)) {
      if (
        isEmpty(params) &&
        isEmpty(filtersPaginationAndSort?.filters) &&
        isEmpty(filtersPaginationAndSort?.pagination)
      ) {
        url = `${url}?${filtersPaginationAndSort.sort}`
      } else {
        url = `${url}&${filtersPaginationAndSort.sort}`
      }
    }
    // eslint-disable-next-line no-empty
  } catch (error) {
    url = `[FTEL-ERROR] : Url marlformée, vérifiez les paramètres de la query : ${url}`
  }

  const queryKey = compact([
    urlTemplate,
    params,
    filtersPaginationAndSort?.filters,
    filtersPaginationAndSort?.pagination,
    filtersPaginationAndSort?.sort,
  ])
  const query = useQuery<T>(queryKey, () => fetcher(method, url), options)

  return { ...query, queryKey }
}

export const useMutationWrapper = (
  method: HttpMethod,
  urlTemplate: string,
  options,
  axiosOptions?: AxiosRequestConfig,
) => {
  return useMutation((variables: MutationFunctionVariables) => {
    const objectVariables = variables || {}
    const { data, ...params } = objectVariables
    return fetcher(method, urlcat(urlTemplate, params), data, axiosOptions)
  }, options)
}

function fakeFetcher<T>(data) {
  return new Promise<T>((resolve) => {
    setTimeout(() => resolve(data), 800)
  })
}

export function useFakeMutation<T>(fakeData, options) {
  return useMutation(() => fakeFetcher<T>(fakeData), options)
}

export function useFakeQuery<T>(queryKey, fakeData, options) {
  return useQuery<T>(queryKey, () => fakeFetcher<T>(fakeData), options)
}
