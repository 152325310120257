import { useQueryWrapper } from 'domains/helpers'
import { STEP, STEPS } from './templates'
import { Step } from './type'

export function useStepsQuery(params?, options?) {
  return useQueryWrapper<Step[]>('GET', STEPS, params, options)
}

export function useStepQuery(params?, options?) {
  return useQueryWrapper<Step>('GET', STEP, params, options)
}
