import { useQueryWrapper } from 'domains/helpers'
import { PaginatedType } from 'domains/types'
import { ROLE, ROLES } from './templates'
import { Role } from './types'

export function useRolesQuery(params?, options?, filtersPaginationAndSort?) {
  return useQueryWrapper<PaginatedType<Role>>(
    'GET',
    ROLES,
    params,
    options,
    filtersPaginationAndSort,
  )
}

export function useRoleQuery(params?, options?) {
  return useQueryWrapper<Role>('GET', ROLE, params, options)
}
