/* eslint-disable react/jsx-props-no-spreading */
import { Delete, Edit, Star } from '@mui/icons-material'
import { Box, IconButton, Paper, Typography } from '@mui/material'
import { useConfirm } from 'contexts/ConfirmContext'
import { ERROR, SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { useDeleteThematicMutation } from 'domains/thematics/mutations'
import { THEMATICS } from 'domains/thematics/templates'
import { THEMATICS_EDIT_PATH } from 'enums/paths'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import urlcat from 'urlcat'
import { MIN_HEIGHT_THEMATIC_CARD } from './constants'
import { Props } from './types'

function StyledIconButton(props) {
  return (
    <IconButton size="small" sx={{ color: 'background.default' }} {...props} />
  )
}

function ThematicCard({ thematic }: Props) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { confirm } = useConfirm()
  const deleteThematic = useDeleteThematicMutation()
  const { popSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const handleDelete = () => {
    confirm(t('confirm-delete-thematic'), t('irreversible-action')).then(() =>
      deleteThematic.mutate(
        { id: thematic.id },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(THEMATICS)
            popSnackbar(t('thematic-delete-success'), SUCCESS)
          },
          onError: () => {
            popSnackbar(t('error'), ERROR)
          },
        },
      ),
    )
  }

  return (
    <Paper
      sx={{
        bgcolor: 'primary.main',
        p: 1,
        minHeight: MIN_HEIGHT_THEMATIC_CARD,
        height: '100%',
      }}
    >
      <Box sx={{ display: 'flex' }}>
        {thematic.isPrincipal && (
          <Star sx={{ color: 'background.default', m: 0.5 }} />
        )}
        <Box sx={{ flex: 1 }} />
        <StyledIconButton
          onClick={() =>
            navigate(urlcat(THEMATICS_EDIT_PATH, { id: thematic.id }))
          }
        >
          <Edit />
        </StyledIconButton>
        <StyledIconButton onClick={handleDelete}>
          <Delete />
        </StyledIconButton>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {thematic.picture?.url ? (
          <Box
            component="img"
            src={thematic.picture.url}
            sx={{
              height: 90,
              width: '100%',
              objectFit: 'contain',
              borderRadius: 1,
              mb: 1,
            }}
          />
        ) : (
          <Box sx={{ height: 90, mb: 1, bgcolor: 'background.default' }} />
        )}
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Typography noWrap textAlign="center">
            {thematic.name}
          </Typography>
        </Box>
        <Typography
          variant="body2"
          textAlign="center"
          sx={{
            color: 'text.secondary',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: 3 /* number of lines to show */,
            WebkitBoxOrient: 'vertical',
            minHeight: 60,
            mb: 1,
          }}
        >
          {thematic.description}
        </Typography>
      </Box>
    </Paper>
  )
}

export default ThematicCard
