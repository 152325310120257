import { Add, Delete, Edit } from '@mui/icons-material'
import { Box, Button, IconButton, Typography } from '@mui/material'
import { useConfirm } from 'contexts/ConfirmContext'
import { SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { useDeleteGenderMutation } from 'domains/gender/mutations'
import { useGendersQuery } from 'domains/gender/queries'
import { GENDERS } from 'domains/gender/templates'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import GenderDialog from './GenderDialog'

function GenderManager() {
  const { t } = useTranslation()
  const { confirm } = useConfirm()
  const deleteGender = useDeleteGenderMutation()
  const { popSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const { data: genders } = useGendersQuery()
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [genderSelected, setGenderSelected] = useState(false)

  const handleDelete = async (id) => {
    await confirm(t('irreversible-action'), t('gender-delete-confirmation'))
    await deleteGender.mutateAsync({ id })
    popSnackbar(t('gender-delete-success'), SUCCESS)
    queryClient.invalidateQueries({ queryKey: [GENDERS] })
  }

  const handleSelectGender = (id = null) => {
    setGenderSelected(id)
    setOpenEditDialog(true)
  }

  const handleClose = () => {
    setOpenEditDialog(false)
    queryClient.invalidateQueries({ queryKey: [GENDERS] })
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            width: 'fit-content',
            pr: 6,
            pb: 1,
          }}
        >
          {t('gender')}
        </Typography>
        <Button
          sx={{ mb: 1 }}
          startIcon={<Add />}
          onClick={() => handleSelectGender()}
        >
          {t('add-gender')}
        </Button>
      </Box>
      <Box
        sx={{
          height: (theme) => theme.spacing(70),
          overflow: 'hidden',
          backgroundColor: 'primary.main',
          p: 1.5,
          borderRadius: (theme) => theme.spacing(0.5),
        }}
      >
        <Box
          sx={{
            overflowY: 'scroll',
            overflowX: 'unset',
            width: '100%',
            height: '100%',
            '&::-webkit-scrollbar': { display: 'none' },
            boxSizing: 'content-box',
            scrollbarWidth: 'none',
          }}
        >
          {genders?.data?.map((gender) => (
            <Box
              key={gender.id}
              sx={{
                m: 1,
                p: 0.5,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: 'common.white',
                borderRadius: (theme) => theme.spacing(0.5),
              }}
            >
              <Typography> {gender.name}</Typography>
              <Box>
                <IconButton
                  size="small"
                  onClick={() => handleSelectGender(gender.id)}
                >
                  <Edit
                    sx={{
                      borderRadius: '50%',
                      bgcolor: 'primary.main',
                      color: 'common.white',
                      border: (theme) =>
                        `3px solid ${theme.palette.primary.main}`,
                    }}
                  />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => handleDelete(gender.id)}
                >
                  <Delete
                    sx={{
                      borderRadius: '50%',
                      bgcolor: 'primary.main',
                      color: 'common.white',
                      border: (theme) =>
                        `3px solid ${theme.palette.primary.main}`,
                    }}
                  />
                </IconButton>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      <GenderDialog
        open={openEditDialog}
        onClose={handleClose}
        genderId={genderSelected}
      />
    </>
  )
}

export default GenderManager
