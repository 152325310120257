/* eslint-disable no-underscore-dangle */
import { Box, Paper, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

function UserInformationCard({ userRun }) {
  const { t } = useTranslation()
  return (
    <Paper
      sx={{
        bgcolor: 'primary.main',
        p: 1,
        mt: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="h2" fontWeight="bold">
            {userRun?.user.firstName}
          </Typography>
          <Typography sx={{ pl: 0.5 }} variant="h4">
            ({userRun?.user.email})
          </Typography>
        </Box>
        <Typography variant="h4">{userRun?.user?.gender?.name}</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 1,
        }}
      >
        <Typography variant="h4">
          {userRun?.user?.town &&
            `${userRun?.user?.town?.name} (${userRun?.user?.town?.postalCode})`}
        </Typography>
        <Typography variant="h4">
          {userRun?.user?._AgeRange} {t('years')}
        </Typography>
      </Box>
    </Paper>
  )
}

export default UserInformationCard
