import { TextField, Typography } from '@mui/material'
import { get, isEmpty } from 'lodash'
import { DateTime } from 'luxon'
import React, { forwardRef } from 'react'
import { useController } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ControlledDatePickerProps } from './types'

const ControlledDatePicker = forwardRef(
  ({ name, control, label = '', ...props }: ControlledDatePickerProps, ref) => {
    const { t } = useTranslation()
    const controller = useController({ name, control })
    const {
      field,
      fieldState: { error },
    } = controller

    if (field.value === undefined) {
      console.error(
        `[FTEL-ERROR] : Le composant ControlledDatePicker '${name}' a une valeur undefined, il faut impérativement donner une valeur par défaut au formulaire (defaultValues)"`,
      )
    }

    const value = DateTime.fromISO(field.value)
    const formattedValue = !value.invalid ? value.toFormat('yyyy-MM-dd') : ''

    const handleChange = (e) => {
      const nextValue = isEmpty(e.target.value)
        ? ''
        : DateTime.fromISO(e.target.value).toISO()

      field.onChange(nextValue)
    }
    return (
      <>
        <Typography variant="body2">{label}</Typography>
        <TextField
          ref={ref}
          type="date"
          {...field}
          value={formattedValue}
          onChange={handleChange}
          error={!!error}
          InputLabelProps={{ shrink: true }}
          helperText={error && get(error, 'message', t('invalid-field'))}
          {...props}
        />
      </>
    )
  },
)

export default ControlledDatePicker
