import {
  gridColumnLookupSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid'

function useGridColumns() {
  const apiRef = useGridApiContext()
  const lookup = useGridSelector(apiRef, gridColumnLookupSelector)

  return lookup
}

export default useGridColumns
