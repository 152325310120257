import { Add } from '@mui/icons-material'
import { Box, Grid, Typography } from '@mui/material'
import { useStepsQuery } from 'domains/steps/queries'
import { isEmpty, max } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import StepsForm from '../StepsForm'
import StepCard from './components/StepCard'

function StepsList({ runId }) {
  const { t } = useTranslation()
  const { data: steps = [], isLoading } = useStepsQuery(
    { runId },
    { enabled: !isEmpty(runId) },
  )
  const [idSelected, setIdSelected] = useState(null)
  const [open, setOpen] = useState(false)

  const nextIndex =
    steps.length > 0 ? max<number>(steps.map(({ index }) => index)) + 1 : 1

  const canDeleteStep = steps.length > 2

  const handleChange = (id) => {
    setOpen(!open)
    setIdSelected(id)
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          alignItems: 'flex-start',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          variant="h2"
          sx={{
            borderBottom: '2px solid',
            borderBottomColor: 'text.primary',
            width: 'fit-content',
            pr: 6,
            pb: 1,
            mb: 2,
          }}
        >
          {t('steps')}
        </Typography>
        <Box
          sx={{
            bgcolor: 'common.black',
            cursor: 'pointer',
            borderRadius: '100%',
            width: (theme) => theme.spacing(3.5),
            height: (theme) => theme.spacing(3.5),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() => handleChange(null)}
        >
          <Add sx={{ color: 'common.white' }} />
        </Box>
      </Box>
      <Grid container spacing={2}>
        {!isLoading &&
          steps &&
          steps.map((step, i) => (
            <Grid key={step.id || i} item xs={12} lg={6}>
              <StepCard
                step={step}
                runId={runId}
                handleChange={handleChange}
                canDeleteStep={canDeleteStep}
              />
            </Grid>
          ))}
      </Grid>
      <StepsForm
        runId={runId}
        open={open}
        close={() => handleChange(null)}
        id={idSelected}
        nextIndex={nextIndex}
      />
    </>
  )
}

export default StepsList
