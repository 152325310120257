import { Add } from '@mui/icons-material'
import { Box } from '@mui/material'
import LinkButton from 'components/atoms/LinkButton'
import { EMAIL_MANAGEMENT_TEMPLATES_CREATE_PATH } from 'enums/paths'
import React from 'react'

function Toolbar() {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      <LinkButton
        to={EMAIL_MANAGEMENT_TEMPLATES_CREATE_PATH}
        variant="outlined"
      >
        <Add />
      </LinkButton>
    </Box>
  )
}

export default Toolbar
