import { isEmpty } from 'lodash'

export function formatEducationLevelsToAutocomplete(educationalLevels) {
  const collectionFormattedTemp = []
  if (!isEmpty(educationalLevels)) {
    educationalLevels.map((element) =>
      collectionFormattedTemp.push({ label: element?.name, id: element?.id }),
    )
  }
  return collectionFormattedTemp
}
