import { Button } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

function LinkButton({ to, ...props }) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Button component={Link} to={to} {...props} />
}

export default LinkButton
