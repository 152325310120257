import { Box, CircularProgress, useTheme } from '@mui/material'
import { ResponsivePie } from '@nivo/pie'
import React from 'react'
import {
  ANCHOR,
  DIRECTION,
  TRANSLATE_X,
  TRANSLATE_Y,
  ITEM_WIDTH,
  ITEM_HEIGHT,
  ITEM_DIRECTION,
  SYMBOL_SIZE,
  SYMBOL_SHAPE,
  MARGIN_RIGHT,
} from './constants'

function ThermaticsRepartition({ statistic = null }) {
  const theme = useTheme()
  if (statistic == null) return <CircularProgress color="secondary" />
  return (
    <Box>
      <Box
        sx={{
          height: theme.spacing(12.5),
          mt: 2,
        }}
      >
        <ResponsivePie
          data={statistic.thematics}
          margin={{ right: MARGIN_RIGHT }}
          innerRadius={0.5}
          colors={statistic.thematics.map((el) => el.color)}
          enableArcLabels={false}
          enableArcLinkLabels={false}
          sortByValue
          legends={[
            {
              data: statistic.thematics.map((item, index) => ({
                color: statistic.thematics.map((el) => el.color)[index],
                id: item.id,
                label: `${item.value} % - ${item.label}`,
              })),
              anchor: ANCHOR,
              direction: DIRECTION,
              justify: true,
              translateX: TRANSLATE_X,
              translateY: TRANSLATE_Y,
              itemsSpacing: 5,
              itemWidth: ITEM_WIDTH,
              itemHeight: ITEM_HEIGHT,
              itemTextColor: theme.palette.common.white,
              itemDirection: ITEM_DIRECTION,
              itemOpacity: 1,
              symbolSize: SYMBOL_SIZE,
              symbolShape: SYMBOL_SHAPE,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemTextColor: theme.palette.common.black,
                  },
                },
              ],
            },
          ]}
        />
      </Box>
    </Box>
  )
}

export default ThermaticsRepartition
