import React from 'react'
import {
  MapContainer,
  Popup,
  TileLayer,
  Polyline,
  Marker,
  Tooltip,
} from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import { LatLngBounds } from 'leaflet'
import { Box, Typography, useTheme } from '@mui/material'
import { isEmpty } from 'lodash'
import { stepIcon, endIcon, startIcon } from 'helpers/leafletIcons'
import { formatDuration, formatDistance } from 'helpers/formatter'

import 'config/lealfetStyle/leaflet.css'
import { useTranslation } from 'react-i18next'
import { sortArrayOfObjectByDate } from 'helpers/helper'
import {
  MIN_ACCURACY_IN_M,
  MAX_SPEED_FILTER_IN_M_S as SPEED_FILTER_IN_M_S,
} from './constants'

function PathDashboard({ userRun }) {
  const { t } = useTranslation()
  const theme = useTheme()
  const userRunSteps = [...userRun.userRunSteps]
  const HasCoord =
    userRunSteps.filter((urs) => !isEmpty(urs.locations)).length > 0

  if (!HasCoord) return <Box>{t('no-coordinates')}</Box>

  const coordsFlatted = userRunSteps.map((urs) => urs.locations)?.flat()
  const coordsFlattedAndSorted = sortArrayOfObjectByDate(
    coordsFlatted,
    'dateTime',
  )
  const latitudes = coordsFlattedAndSorted.map((c) => c.latitude)
  const longitudes = coordsFlattedAndSorted.map((c) => c.longitude)
  const minLatitude = Math.min(...latitudes)
  const maxLatitude = Math.max(...latitudes)
  const minLongitude = Math.min(...longitudes)
  const maxLongitude = Math.max(...longitudes)

  const getPaths = () => {
    return userRunSteps.map((urs) => {
      return (
        <Polyline
          key={urs.index}
          positions={sortArrayOfObjectByDate<any>(urs.locations, 'dateTime')
            .filter(
              (l) =>
                l.speed <= SPEED_FILTER_IN_M_S &&
                l.accuracy < MIN_ACCURACY_IN_M,
            )
            .map((l) => [l.latitude, l.longitude])}
          pathOptions={{ color: theme.palette.error.main }}
        >
          <Popup>
            {t('mission')} {urs.index} <br />
            {t('distance-no-unit')} : {formatDistance(urs.distanceInMeters)}
            <br />
            {t('time')} : {formatDuration(urs.durationInMinutes)}
            <br />
          </Popup>
        </Polyline>
      )
    })
  }
  const getMarkers = () => {
    return userRunSteps.map((urs, index) => {
      if (isEmpty(urs.locations)) return null
      const last = urs.locations[urs.locations.length - 1]
      const first = urs.locations[0]

      switch (index) {
        // Premier step du parcours : 🚩 en début de trajectoire et n° du step en fin de trajectoire
        case 0:
          return (
            <>
              <Marker
                key={`Marker_${first.latitude}${first.longitude}`}
                position={[first.latitude, first.longitude]}
                icon={startIcon}
              />
              <Marker
                key={`Marker_${last.latitude}${last.longitude}`}
                position={[last.latitude, last.longitude]}
                icon={stepIcon}
              >
                <Tooltip
                  key={`Tooltip_${last.latitude}${last.longitude}`}
                  permanent
                  direction="center"
                  className="my-labels"
                >
                  {index + 1}
                </Tooltip>
              </Marker>
            </>
          )
        // Dernier step du parcours : petit drapeau en fin de trajectoire 🏁
        case userRunSteps.length - 1:
          return (
            <Marker
              key={`Marker_${last.latitude}${last.longitude}`}
              position={[last.latitude, last.longitude]}
              icon={endIcon}
            />
          )
        // Pour les autres steps marque le n° à la fin de leur trajectoire
        default:
          return (
            <Marker
              key={`Marker_${last.latitude}${last.longitude}`}
              position={[last.latitude, last.longitude]}
              icon={stepIcon}
            >
              <Tooltip
                key={`Tooltip_${last.latitude}${last.longitude}`}
                permanent
                direction="center"
                className="my-labels"
              >
                {index + 1}
              </Tooltip>
            </Marker>
          )
      }
    })
  }

  return (
    <>
      <Typography variant="h2" sx={{ mb: 2 }} fontWeight={700}>
        {t('run-on-map')}
      </Typography>
      <MapContainer
        bounds={
          new LatLngBounds(
            [minLatitude, minLongitude],
            [maxLatitude, maxLongitude],
          )
        }
        boundsOptions={{ padding: [50, 50] }}
        scrollWheelZoom
        style={{ height: '100%', width: '100%' }}
      >
        {/* Template de carte openstreetmap (cf. https://leafletjs.com/reference.html#tilelayer) */}
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        {getPaths()}
        {getMarkers()}
      </MapContainer>
    </>
  )
}
export default PathDashboard
