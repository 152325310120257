import { useQueryWrapper } from 'domains/helpers'
import { PaginatedType } from 'domains/types'
import { RUN, RUNS } from './templates'
import { Run } from './types'

export function useRunsQuery(params?, options?, filtersPaginationAndSort?) {
  return useQueryWrapper<PaginatedType<Run>>(
    'GET',
    RUNS,
    params,
    options,
    filtersPaginationAndSort,
  )
}

export function useRunQuery(params?, options?) {
  return useQueryWrapper<Run>('GET', RUN, params, options)
}
