import { Clear, Search } from '@mui/icons-material'
import { Box, TextField } from '@mui/material'
import { GridToolbarContainer, GridToolbarProps } from '@mui/x-data-grid'
import { debounce, isEmpty } from 'lodash'
import { useFormContext, useFieldArray } from 'react-hook-form'
import * as React from 'react'
import FiltersTag from '../FiltersTag'
import ToolbarColumnsButton from '../ToolbarColumnsButton'
import ToolbarDensityButton from '../ToolbarDensityButton'
import ToolbarFilterButton from '../ToolbarFilterButton'
import ToolbarRefreshButton from '../ToolbarRefeshButton'
import { FormValues } from '../ToolbarFilterButton/types'

const Toolbar = React.forwardRef<HTMLDivElement, GridToolbarProps>(
  function GridToolbar(props, ref) {
    const {
      additionalActions,
      onRefresh,
      search,
      setSearch,
      filterUpdate,
      filtersString,
    } = props

    const [searchInstant, setSearchInstant] = React.useState(search)
    const [searchShownValue, setSearchShownValue] =
      React.useState(searchInstant)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearchUpdate = React.useCallback(
      debounce((value) => {
        setSearch(value)
      }, 1000),
      [],
    )

    React.useEffect(() => {
      setSearchShownValue(searchInstant)
      debouncedSearchUpdate(searchInstant)
    }, [debouncedSearchUpdate, searchInstant])

    const form = useFormContext<FormValues>()

    const {
      fields: filters,
      append,
      remove,
    } = useFieldArray({
      control: form.control,
      name: 'filters',
    })

    return (
      <>
        <GridToolbarContainer
          ref={ref}
          sx={{
            display: 'flex',
            gap: 1,
            m: 1,
            justifyContent: 'space-between',
          }}
        >
          <TextField
            value={searchShownValue}
            size="small"
            onChange={(e) => {
              setSearchInstant(e.target.value)
            }}
            InputProps={{
              startAdornment: <Search sx={{ mr: 1 }} />,
              endAdornment: (
                <Clear
                  onClick={() => setSearchInstant('')}
                  sx={{
                    cursor: 'pointer',
                    opacity: 0.6,
                    visibility: isEmpty(searchShownValue)
                      ? 'hidden'
                      : 'visible',
                  }}
                />
              ),
            }}
          />
          <Box>
            <ToolbarRefreshButton onRefresh={onRefresh} />
            <ToolbarFilterButton
              filterUpdate={filterUpdate}
              filters={filters}
              append={append}
              remove={remove}
              filtersString={filtersString}
            />
            <ToolbarColumnsButton />
            <ToolbarDensityButton />
            {additionalActions}
          </Box>
        </GridToolbarContainer>
        <GridToolbarContainer sx={{ m: 1 }}>
          <FiltersTag
            filtersString={filtersString}
            remove={remove}
            filterUpdate={filterUpdate}
          />
        </GridToolbarContainer>
      </>
    )
  },
)

export default Toolbar
