import { Delete } from '@mui/icons-material'
import { GridActionsCellItem } from '@mui/x-data-grid'
import { useAuth } from 'contexts/AuthContext'
import { useConfirm } from 'contexts/ConfirmContext'
import { SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { useDeleteAppParameterMutation } from 'domains/app-parameters/mutation'
import { APP_PARAMETERS } from 'domains/app-parameters/templates'
import { DefaultRole } from 'domains/roles/enums'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

const useTemplatesGrid = () => {
  const { t } = useTranslation()
  const { confirm } = useConfirm()
  const deleteAppParameter = useDeleteAppParameterMutation()
  const { popSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const handleDelete = async (id) => {
    await confirm(t('irreversible-action'), t('confirm-delete-app-param'))
    await deleteAppParameter.mutateAsync({ id })
    popSnackbar(t('app-parameters-delete-success'), SUCCESS)
    queryClient.invalidateQueries({ queryKey: [APP_PARAMETERS] })
  }

  const { is } = useAuth()
  const isSupervisor = is(DefaultRole.SUPERVISOR)

  const columns = [
    {
      field: 'key',
      headerName: t('key'),
      width: 300,
      editable: isSupervisor,
      filterable: false,
    },
    {
      field: 'description',
      headerName: t('description'),
      width: 700,
      editable: isSupervisor,
      filterable: false,
    },
    {
      field: 'value',
      headerName: t('value'),
      flex: 1,
      editable: true,
      filterable: false,
    },
    {
      field: 'isOnlyForSupervisor',
      headerName: t('only-for-supervisors'),
      type: 'boolean',
      flex: 1,
      editable: true,
      hide: !isSupervisor,
      filterable: false,
    },
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      getActions: ({ id }) => {
        if (isSupervisor) {
          return [
            <GridActionsCellItem
              icon={<Delete />}
              onClick={() => handleDelete(id)}
              label={t('delete')}
            />,
          ]
        }
        return []
      },
    },
  ]

  return { columns }
}

export default useTemplatesGrid
