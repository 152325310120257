import React, { useState } from 'react'
import Header from 'components/molecules/Header'
import { useTranslation } from 'react-i18next'
import { useLevelDefaultssQuery } from 'domains/level-defaults/queries'
import { Grid } from '@mui/material'
import CardButton from 'components/atoms/CardButton'
import { AddCircleOutline } from '@mui/icons-material'
import { max } from 'lodash'
import LevelDefaultCard from './components/LevelDefaultCard'
import LevelDefaultForm from '../LevelDefaultForm'
import { MIN_HEIGHT_LEVEL_DEFAULT_CARD } from './components/LevelDefaultCard/constants'

function LevelDefaultsList() {
  const { t } = useTranslation()
  const [idSelected, setIdSelected] = useState(null)
  const [open, setOpen] = useState(false)
  const { data: levelDefaults, isLoading } = useLevelDefaultssQuery()
  const nextIndex =
    levelDefaults?.data && levelDefaults?.data.length > 0
      ? max<number>(levelDefaults?.data.map(({ index }) => index)) + 1
      : 1
  const handleChange = (id) => {
    setOpen(!open)
    setIdSelected(id)
  }
  return (
    <>
      <Header title={t('level-defaults')} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={3}>
          <CardButton
            Icon={AddCircleOutline}
            text="add-level-default"
            clickAction={() => handleChange(null)}
            minHeight={MIN_HEIGHT_LEVEL_DEFAULT_CARD}
          />
        </Grid>
        {!isLoading &&
          levelDefaults?.data &&
          levelDefaults?.data.map((levelDefault, index) => (
            <Grid key={levelDefault.id || index} item xs={12} md={6} lg={3}>
              <LevelDefaultCard
                levelDefault={levelDefault}
                handleChange={handleChange}
              />
            </Grid>
          ))}
      </Grid>
      <LevelDefaultForm
        open={open}
        close={() => handleChange(null)}
        id={idSelected}
        nextIndex={nextIndex}
      />
    </>
  )
}
export default LevelDefaultsList
