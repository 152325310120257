/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button } from '@mui/material'
import ControlledTextField from 'components/molecules/ControlledTextField'
import { useGenderQuery } from 'domains/gender/queries'
import React, { useEffect } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useGenderForm from './form'

function GenderForm(props) {
  const { onClose, genderId } = props
  const { t } = useTranslation()
  const { form, onSubmit } = useGenderForm(genderId)
  const {
    formState: { isSubmitSuccessful, isDirty },
    handleSubmit,
  } = form
  const { data: gender } = useGenderQuery(
    { id: genderId },
    { enabled: !!genderId },
  )

  useEffect(() => {
    if (isSubmitSuccessful) {
      form.reset()
      onClose()
    }
  }, [isSubmitSuccessful])

  useEffect(() => {
    if (gender) {
      form.reset(gender)
    }
  }, [gender])

  return (
    <FormProvider {...form}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <ControlledTextField
          autoFocus
          name="name"
          label={t('name')}
          fullWidth
          size="small"
        />
        <Button
          size="small"
          type="submit"
          sx={{ mt: 1, display: 'flex' }}
          disabled={!isDirty}
        >
          {t('confirm')}
        </Button>
      </Box>
    </FormProvider>
  )
}

export default GenderForm
