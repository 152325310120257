import { Box } from '@mui/material'
import { orderBy } from 'lodash'
import React, { useState } from 'react'
import Level from './components/Level'
import VerticalSelector from './components/VerticalSelector'
import { Props } from './types'

function LevelsManager({ thematic }: Props) {
  const orderedLevels = orderBy(thematic?.levels, ['index'])
  const [selected, setSelected] = useState(orderedLevels?.[0]?.id || null)

  return (
    <Box sx={{ display: 'flex' }}>
      <VerticalSelector
        thematic={thematic}
        selected={selected}
        setSelected={setSelected}
      />
      <Level thematicId={thematic.id} id={selected} setSelected={setSelected} />
    </Box>
  )
}

export default LevelsManager
