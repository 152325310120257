import Uploady, {
  UploadyContext,
  useItemFinalizeListener,
  useUploady,
} from '@rpldy/uploady'
import { asUploadButton } from '@rpldy/upload-button'
import React, { useContext } from 'react'
import { alpha, Box } from '@mui/material'
import { MEDIA_TYPE_IMAGE } from './enums'

const UploadBtn = asUploadButton((props) => {
  const uploady = useUploady()
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <Box
      {...props}
      onClick={(event) => {
        event.stopPropagation()
        uploady.showFileUpload()
      }}
      sx={{
        height: (theme) => theme.spacing(5),
        width: '100%',
        bgcolor: 'common.white',
        position: 'relative',
        borderRadius: 1,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          bgcolor: (theme) => alpha(theme.palette.primary.main, 0.5),
          '&:hover': {
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.8),
          },
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 1,
        }}
      >
        Upload
      </Box>
    </Box>
  )
})

function Upload({ onChange, value }) {
  useContext(UploadyContext)
  useItemFinalizeListener((item) => {
    onChange({ url: item.uploadResponse?.data, type: MEDIA_TYPE_IMAGE })
  })
  return (
    <Box>
      <UploadBtn />
      <Box sx={{ width: '100%', mt: 1, '& img': { width: '100%' } }}>
        {value && <img src={value?.url} alt="upload" />}
      </Box>
    </Box>
  )
}

function UploadFile({ onChange, value }) {
  return (
    <Uploady destination={{ url: process.env.REACT_APP_PUBLIC_MEDIA_URL }}>
      <Upload onChange={onChange} value={value} />
    </Uploady>
  )
}

export default UploadFile
