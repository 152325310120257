import StandardLayout from 'components/templates/StandardLayout'
import * as React from 'react'
import { Outlet } from 'react-router-dom'

function App() {
  return (
    <StandardLayout>
      <Outlet />
    </StandardLayout>
  )
}

export default App
