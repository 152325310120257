import { Grid } from '@mui/material'
import Header from 'components/molecules/Header'
import { isEmpty } from 'lodash'
import React, { useEffect } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import {
  useCreateRunMutation,
  useUpdateRunMutation,
} from 'domains/runs/mutations'
import { ERROR, SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { useRunQuery } from 'domains/runs/queries'
import { RUNS_PATH, RUNS_EDIT_PATH } from 'enums/paths'
import urlcat from 'urlcat'
import Form from './components/Form'
import useCreateRunForm from './form'
import StepsList from '../StepsList'
import { formatRunToForm } from './formatters'

function RunsForm() {
  const { t } = useTranslation()
  const params = useParams()
  const { id } = params
  const isEditForm = !isEmpty(id)
  const form = useCreateRunForm()
  const createRun = useCreateRunMutation()
  const updateRun = useUpdateRunMutation()
  const navigate = useNavigate()
  const { popSnackbar } = useSnackbar()
  const { data: run } = useRunQuery({ id }, { enabled: !isEmpty(id) })

  useEffect(() => {
    if (run) {
      form.reset(formatRunToForm(run))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [run])

  const onSubmit = (values) => {
    if (!isEditForm) {
      return createRun.mutate(
        {
          data: values,
        },
        {
          onSuccess: (response) => {
            navigate(urlcat(RUNS_EDIT_PATH, { id: response.id }))
          },
          onError: () => {
            popSnackbar(t('error'), ERROR)
          },
        },
      )
    }
    return updateRun
      .mutateAsync({ id, data: values })
      .then(() => popSnackbar(t('udapte-run-access'), SUCCESS))
  }

  return (
    <>
      <Header
        backButton
        backButtonUrl={RUNS_PATH}
        title={!isEditForm ? t('create-run') : t('edit-run')}
      />
      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={8} lg={6}>
          <FormProvider {...form}>
            <Form
              watch={form.watch}
              onSubmit={form.handleSubmit(onSubmit)}
              variant={isEditForm ? 'edit' : 'create'}
            />
          </FormProvider>
        </Grid>
        {isEditForm && (
          <Grid item xs={12} md={4} lg={6}>
            <StepsList runId={id} />
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default RunsForm
