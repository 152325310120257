import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import ControlledTextField from 'components/molecules/ControlledTextField'
import { SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { LEVEL } from 'domains/levels/templates'
import {
  useCreateThematicLevelMutation,
  useUpdateThematicLevelMutation,
} from 'domains/thematics/mutations'
import { THEMATIC } from 'domains/thematics/templates'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Props } from './types'

function LevelForm({
  open,
  setOpen,
  variant,
  thematicId,
  level = null,
  onSuccess,
}: Props) {
  const form = useFormContext()
  const { popSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const handleClose = () => setOpen(false)
  const createLevel = useCreateThematicLevelMutation()
  const updateLevel = useUpdateThematicLevelMutation()
  const queryClient = useQueryClient()

  const onSubmit = async (values) => {
    if (variant === 'create') {
      return createLevel
        .mutateAsync({ id: thematicId, data: values })
        .then((res) => {
          setOpen(false)
          popSnackbar(t('level-create-success'), SUCCESS)
          queryClient.invalidateQueries([THEMATIC, { id: thematicId }])
          form.reset()
          if (onSuccess) {
            onSuccess(res)
          }
        })
    }

    if (variant === 'edit') {
      return updateLevel
        .mutateAsync({ id: thematicId, levelId: values.id, data: values })
        .then((res) => {
          setOpen(false)
          popSnackbar(t('level-edit-success'), SUCCESS)
          queryClient.invalidateQueries([LEVEL, { id: values.id }])
          if (onSuccess) {
            onSuccess(res)
          }
        })
    }

    return null
  }

  return (
    <Dialog open={open} maxWidth="sm" fullWidth onClose={handleClose}>
      <Box component="form" onSubmit={form.handleSubmit(onSubmit)}>
        <DialogTitle>
          {variant === 'create' ? t('level-create') : t('level-edit')}
        </DialogTitle>
        <DialogContent
          sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
        >
          <ControlledTextField fullWidth name="name" label={t('name')} />
          <ControlledTextField
            fullWidth
            multiline
            minRows={6}
            maxRows={6}
            name="description"
            label={t('description')}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('cancel')}</Button>
          <Button variant="contained" type="submit">
            {variant === 'create' ? t('common-save') : t('common-update')}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default LevelForm
