import { useAuth } from 'contexts/AuthContext'
import { LOGIN_PATH } from 'enums/paths'
import React, { useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'

function Logout() {
  const { logout } = useAuth()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    logout().finally(() => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return !loading && <Navigate to={LOGIN_PATH} />
}

export default Logout
