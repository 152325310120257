import { Typography } from '@mui/material'
import React from 'react'

export function FormatRank(rank) {
  return (
    <Typography color="primary.dark" variant="h1">
      {rank}
      {rank === 1 ? <sup>er</sup> : <sup>ème</sup>}
    </Typography>
  )
}
