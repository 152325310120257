import { useMutationWrapper } from 'domains/helpers'
import { CUSTOMER, CUSTOMERS } from './templates'

export function useCreateCustomerMutation(options?) {
  return useMutationWrapper('POST', CUSTOMERS, options)
}

export function useUpdateCustomerMutation(options?) {
  return useMutationWrapper('PUT', CUSTOMER, options)
}

export function usePatchCustomerMutation(options?) {
  return useMutationWrapper('PATCH', CUSTOMER, options)
}

export function useDeleteCustomerMutation(options?) {
  return useMutationWrapper('DELETE', CUSTOMER, options)
}
