import { yupResolver } from '@hookform/resolvers/yup'
import { toUpper } from 'lodash'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

function useCreateMailTemplateForm() {
  const { t } = useTranslation()
  const defaultValues = {
    key: '',
    name: '',
    subject: '',
  }

  const validationSchema = Yup.object().shape({
    key: Yup.string()
      .min(3, t('min-3-char'))
      .required(t('required-field'))
      .transform((prev) => toUpper(prev)),
    name: Yup.string().min(3, t('min-3-char')).required(t('required-field')),
    subject: Yup.string().min(3, t('min-3-char')).required(t('required-field')),
  })

  return useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })
}

export default useCreateMailTemplateForm
