import { isEmpty } from 'lodash'

export function formatGenderToAutocomplete(genders) {
  const collectionFormattedTemp = []
  if (!isEmpty(genders)) {
    genders.map((element) =>
      collectionFormattedTemp.push({ label: element?.name, id: element?.id }),
    )
  }
  return collectionFormattedTemp
}
