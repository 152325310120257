export const addOp = (path: string, value: any) => ({
  op: 'add',
  path,
  value,
})

export const replaceOp = (path: string, value: any) => ({
  op: 'replace',
  path,
  value,
})

export const removeOp = (path: string, value?: any) => ({
  op: 'remove',
  path,
  ...(value ? { value } : {}),
})

export const copyOp = (from: string, path: string) => ({
  op: 'copy',
  from,
  path,
})

export const moveOp = (from: string, path: string) => ({
  op: 'move',
  from,
  path,
})

export const testOp = (path: string, value: any) => ({
  op: 'test',
  path,
  value,
})
