import React from 'react'
import { Box, Paper, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { formatDuration, formatDistance } from 'helpers/formatter'
import {
  checkNullableDate,
  DateToLocalDateString,
  DateToLocalTimeString,
} from './formatters'
import { NEEDED_STEPS } from './constants'

function UseRunInformations({ userRun }) {
  const { t } = useTranslation()
  // eslint-disable-next-line no-param-reassign

  const hasEndTime = checkNullableDate(userRun.endDate)

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 1,
        width: 1,
        mt: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h2" fontWeight="bold" sx={{ mb: 1 }}>
          {`${t('on-date')} ${DateToLocalDateString(userRun.startDate)} ${
            hasEndTime ? t('from-date') : t('at')
          } ${DateToLocalTimeString(userRun.startDate)} ${
            hasEndTime
              ? `${t('at')} ${DateToLocalTimeString(userRun.endDate)}`
              : ''
          }`}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex' }}>
        {userRun.distanceInMeters > 0 && (
          <Paper
            sx={{
              minWidth: '15%',
              p: 1,
              mr: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h3">
              {formatDistance(userRun.distanceInMeters)}
            </Typography>
          </Paper>
        )}
        {userRun.durationInMinutes > 0 && (
          <Paper
            sx={{
              minWidth: '15%',
              p: 1,
              mr: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h3">
              {formatDuration(userRun.durationInMinutes)}
            </Typography>
          </Paper>
        )}
        <Paper
          sx={{
            minWidth: '15%',
            p: 1,
            mr: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color: (theme) =>
              userRun.userRunSteps.filter((u) => u.dateMarked != null)
                .length === NEEDED_STEPS
                ? theme.palette.success.main
                : theme.palette.error.main,
          }}
        >
          <Typography variant="h2" sx={{ mb: 1 }}>
            {`${
              userRun.userRunSteps.filter((u) => u.dateMarked != null).length
            }/5`}
          </Typography>
          <Typography variant="h2">{t('steps')}</Typography>
        </Paper>
      </Box>
    </Box>
  )
}
export default UseRunInformations
