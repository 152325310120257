import { useQueryWrapper } from 'domains/helpers'
import { Level } from 'domains/levels/types'
import { Mission } from 'domains/missions/types'
import { PaginatedType } from 'domains/types'
import {
  THEMATIC,
  THEMATICS,
  THEMATIC_LEVEL,
  THEMATIC_LEVELS,
  THEMATIC_LEVEL_MISSION,
  THEMATIC_LEVEL_MISSIONS,
} from './templates'
import { Thematic } from './types'

export function useThematicsQuery(
  params?,
  options?,
  filtersPaginationAndSort?,
) {
  return useQueryWrapper<PaginatedType<Thematic>>(
    'GET',
    THEMATICS,
    params,
    options,
    filtersPaginationAndSort,
  )
}

export function useThematicQuery(params?, options?) {
  return useQueryWrapper<Thematic>('GET', THEMATIC, params, options)
}

export function useThematicLevelsQuery(params?, options?) {
  return useQueryWrapper<Level>('GET', THEMATIC_LEVELS, params, options)
}

export function useThematicLevelQuery(params?, options?) {
  return useQueryWrapper<Level>('GET', THEMATIC_LEVEL, params, options)
}

export function useThematicLevelMissionsQuery(params?, options?) {
  return useQueryWrapper<Mission[]>(
    'GET',
    THEMATIC_LEVEL_MISSIONS,
    params,
    options,
  )
}

export function useThematicLevelMissionQuery(params?, options?) {
  return useQueryWrapper<Mission>(
    'GET',
    THEMATIC_LEVEL_MISSION,
    params,
    options,
  )
}
