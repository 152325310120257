import { Close } from '@mui/icons-material'
import { alpha, IconButton, ListItem, MenuItem, Tooltip } from '@mui/material'
import ControlledHiddenInput from 'components/molecules/ControlledHiddenInput'
import ControlledSelect from 'components/molecules/ControlledSelect'
import { find, get, map } from 'lodash'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { getEligibleFields } from '../../helpers'
import useGridColumns from '../../hooks/useGridColumns'
import ToolbarFilterRowInput from '../ToolbarFilterRowInput'
import ToolbarFilterRowOperators from '../ToolbarFilterRowOperators'

function ToolbarFilterRow({ filter, index, onRemove }) {
  const { t } = useTranslation()
  const { key } = filter
  const { watch, setValue } = useFormContext()
  const columns = useGridColumns()
  const eligibleColumns = getEligibleFields(columns)
  const field = watch(`filters.${index}.field`)

  useEffect(() => {
    const fieldObject = find(columns, ['field', field])
    setValue(`filters.${index}.filterKey`, get(fieldObject, 'filterKey', field))
    setValue(
      `filters.${index}.headerName`,
      get(fieldObject, 'headerName', fieldObject?.headerName),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field])

  return (
    <ListItem disablePadding sx={{ gap: 2, mt: 1, alignItems: 'flex-start' }}>
      <Tooltip title={t('remove-filter')}>
        <IconButton
          color="error"
          size="small"
          onClick={() => onRemove(key)}
          sx={{
            bgcolor: 'error.main',
            height: (theme) => theme.spacing(2.5),
            width: (theme) => theme.spacing(2.5),
            '&:hover': {
              bgcolor: (theme) => alpha(theme.palette.error.main, 0.7),
            },
            mt: 1,
          }}
        >
          <Close
            sx={{
              height: (theme) => theme.spacing(2.25),
              width: (theme) => theme.spacing(2.25),
              color: 'common.white',
            }}
          />
        </IconButton>
      </Tooltip>
      <ControlledHiddenInput name={`filters.${index}.filterKey`} />
      <ControlledSelect
        name={`filters.${index}.field`}
        label={t('field')}
        sx={{ width: 150 }}
      >
        {map(eligibleColumns, ({ field: columnField, headerName }) => (
          <MenuItem key={columnField} value={columnField}>
            {headerName}
          </MenuItem>
        ))}
      </ControlledSelect>
      <ToolbarFilterRowOperators index={index} />
      <ToolbarFilterRowInput index={index} />
    </ListItem>
  )
}

export default ToolbarFilterRow
