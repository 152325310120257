import { Box, CircularProgress, Typography } from '@mui/material'
import React from 'react'
import { MIN_HEIGHT_CARD_CONTENT } from '../../constants'

function MonthlyEvolution({ statistic = null }) {
  if (statistic == null) return <CircularProgress color="secondary" />
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: MIN_HEIGHT_CARD_CONTENT,
      }}
    >
      <Typography color="text.secondary" variant="h1">
        {statistic?.monthlyAudienceEvolution} %
      </Typography>
    </Box>
  )
}

export default MonthlyEvolution
