import { isNil } from 'lodash'
import {
  BASE_10,
  NUMBER_OF_MINUTES_IN_ONE_HOUR,
  ONE_KM_IN_METER,
} from './constant'

export function formatValuesWithEditor(values, design, html) {
  return {
    ...values,
    design: JSON.stringify(design),
    html,
  }
}

export function formatDuration(durationInMinute, showSeconds = true) {
  let res = ''
  const hours = Math.floor(durationInMinute / NUMBER_OF_MINUTES_IN_ONE_HOUR)
  const minutesLeft = Math.floor(
    durationInMinute % NUMBER_OF_MINUTES_IN_ONE_HOUR,
  )
  const seconds = (
    (durationInMinute - parseInt(durationInMinute, BASE_10)) *
    NUMBER_OF_MINUTES_IN_ONE_HOUR
  ).toFixed(0)
  if (hours > 0) {
    res += `${hours}h`
  }
  if (minutesLeft > 0) {
    res += `${minutesLeft}m`
  }
  if (showSeconds && seconds !== '0') {
    res += `${seconds}s`
  }
  return res
}

export function formatDistance(distanceInMeter) {
  if (isNil(distanceInMeter)) return '0 m'
  if (distanceInMeter < ONE_KM_IN_METER) return `${distanceInMeter} m`
  return `${Math.fround(distanceInMeter / ONE_KM_IN_METER).toFixed(1)} km `
}
