/* eslint-disable react/jsx-props-no-spreading */
import { Box, Button, Grid } from '@mui/material'
import ControlledTextField from 'components/molecules/ControlledTextField'
import ControlledUploadFile from 'components/molecules/ControlledUploadFile'
import React from 'react'
import { useTranslation } from 'react-i18next'

function Form({ onSubmit, variant }) {
  const { t } = useTranslation()

  return (
    <Box
      component="form"
      onSubmit={onSubmit}
      sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <ControlledTextField
            type="number"
            name="index"
            label={t('index')}
            fullWidth
            size="small"
            sx={{ '& .MuiOutlinedInput-root': { bgcolor: 'common.white' } }}
          />
          <Box sx={{ mt: 1 }}>
            <ControlledTextField
              name="name"
              label={t('name')}
              fullWidth
              size="small"
              sx={{ '& .MuiOutlinedInput-root': { bgcolor: 'common.white' } }}
            />
          </Box>
          <Box sx={{ mt: 1 }}>
            <ControlledTextField
              name="description"
              label={t('description')}
              multiline
              minRows={6}
              maxRows={6}
              fullWidth
              size="small"
              sx={{ '& .MuiOutlinedInput-root': { bgcolor: 'common.white' } }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <ControlledUploadFile name="picture" label={t('picture')} />
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          variant="contained"
          type="submit"
          sx={{ bgcolor: 'common.black', color: 'common.white' }}
        >
          {variant === 'create'
            ? t('add-level-default')
            : t('update-level-default')}
        </Button>
      </Box>
    </Box>
  )
}

export default Form
