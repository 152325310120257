/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button } from '@mui/material'
import ControlledSwitch from 'components/molecules/ControlledSwitch'
import ControlledTextField from 'components/molecules/ControlledTextField'
import { useOfferTypeQuery } from 'domains/offer-types/queries'
import React, { useEffect } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useOfferTypeForm from './form'

function OfferTypeForm(props) {
  const { onClose, offerTypeId } = props
  const { t } = useTranslation()
  const { form, onSubmit } = useOfferTypeForm(offerTypeId)
  const {
    formState: { isSubmitSuccessful, isDirty },
    handleSubmit,
  } = form
  const { data: offerType } = useOfferTypeQuery(
    { id: offerTypeId },
    { enabled: !!offerTypeId },
  )

  useEffect(() => {
    if (isSubmitSuccessful) {
      form.reset()
      onClose()
    }
  }, [isSubmitSuccessful])

  useEffect(() => {
    if (offerType) {
      form.reset(offerType)
    }
  }, [offerType])

  return (
    <FormProvider {...form}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <ControlledTextField
          autoFocus
          label={t('name')}
          name="name"
          fullWidth
          size="small"
        />
        <ControlledTextField
          autoFocus
          label={t('code')}
          name="code"
          fullWidth
          size="small"
        />
        <ControlledTextField
          autoFocus
          label={t('description')}
          name="description"
          fullWidth
          multiline
          minRows={4}
        />
        <ControlledTextField
          autoFocus
          type="number"
          size="small"
          label={t('month-validity-duration')}
          name="defaultMonthDuration"
          fullWidth
        />
        <ControlledSwitch
          sx={{ flexDirection: 'row', alignItems: 'center' }}
          label={t('dashboard-access')}
          name="dashboardAccess"
        />
        <Button
          size="small"
          type="submit"
          sx={{ mt: 1, display: 'flex' }}
          disabled={!isDirty}
        >
          {t('confirm')}
        </Button>
      </Box>
    </FormProvider>
  )
}

export default OfferTypeForm
