import axios from 'axios'
import LoadingScreen from 'components/atoms/LoadingScreen'
import {
  clearJwtToken,
  fetcher,
  getJwtToken,
  setJwtToken,
} from 'domains/helpers'
import { DefaultRole } from 'domains/roles/enums'
import { get } from 'lodash'
import { useAuthenticateMutation } from 'domains/users/mutations'
import { REVOKE_TOKEN, USER_ME } from 'domains/users/templates'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

const AuthContext = createContext(undefined)

function AuthProvider({ children }) {
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const authenticate = useAuthenticateMutation()

  useEffect(() => {
    if (!getJwtToken()) {
      setUser(null)
      setLoading(false)
    } else if (!user && !!getJwtToken()) {
      setLoading(true)
      fetcher('GET', USER_ME)
        .then((userResponse) => setUser(userResponse))
        .catch(() => setUser(null))
        .finally(() => setLoading(false))
    } else {
      setLoading(false)
    }
  }, [user])

  const login = useCallback(
    (values) => {
      return authenticate
        .mutateAsync({ data: values })
        .then(({ token, user: userResponse }) => {
          setUser(userResponse)
          setJwtToken(token)
        })
    },
    [authenticate],
  )

  const logout = useCallback(() => {
    return axios
      .post(REVOKE_TOKEN, null, {
        baseURL: process.env.REACT_APP_API_URL,
        withCredentials: true,
      })
      .finally(() => {
        clearJwtToken()
        setUser(null)
      })
  }, [])

  const is = useCallback(
    (role: DefaultRole): boolean => {
      return get(user, 'roles[0].key') === role
    },
    [user],
  )

  const values = useMemo(
    () => ({
      user,
      loading,
      login,
      logout,
      is,
    }),
    [login, logout, user, loading, is],
  )

  if (loading) {
    return <LoadingScreen />
  }
  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

const useAuth = () => {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}

export { AuthProvider, useAuth }
