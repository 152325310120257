import { Delete, Edit } from '@mui/icons-material'
import { GridActionsCellItem, GridSortModel } from '@mui/x-data-grid'
import { useConfirm } from 'contexts/ConfirmContext'
import { SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { useEducationalLevelsQuery } from 'domains/educationalLevels/queries'
import { useGendersQuery } from 'domains/gender/queries'
import { useTownsQuery } from 'domains/towns/queries'
import { useDeleteUserMutation } from 'domains/users/mutations'
import { USERS } from 'domains/users/templates'
import { SortDirection } from 'enums/filters'
import { USERS_EDIT_PATH } from 'enums/paths'
import { DateTime } from 'luxon'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import urlcat from 'urlcat'

const useUsersGrid = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { confirm } = useConfirm()
  const deleteUser = useDeleteUserMutation()
  const { popSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const handleDelete = async (id) => {
    await confirm(t('irreversible-action'), t('user-delete-confirmation'))
    await deleteUser.mutateAsync({ id })
    popSnackbar(t('user-delete-success'), SUCCESS)
    queryClient.invalidateQueries({ queryKey: [USERS] })
  }
  const { data: genders } = useGendersQuery()
  const { data: educationalLevels } = useEducationalLevelsQuery()
  const { data: towns } = useTownsQuery()

  const columns = [
    {
      field: 'createDate',
      headerName: t('create-date'),
      width: 200,
      editable: false,
      type: 'date',
      filterable: false,
      renderCell: (params) => {
        const date = DateTime.fromISO(params.value).toLocaleString(
          DateTime.DATE_SHORT,
        )
        const hour = DateTime.fromISO(params.value).toLocaleString(
          DateTime.TIME_24_SIMPLE,
        )
        return `${date} - ${hour}`
      },
    },
    {
      field: 'firstName',
      headerName: t('firstname'),
      flex: 1,
      editable: false,
    },
    {
      field: 'lastName',
      headerName: t('name'),
      flex: 1,
      editable: false,
    },
    {
      field: 'email',
      headerName: t('email'),
      flex: 1,
      editable: false,
    },
    {
      field: 'gender.name',
      headerName: t('gender'),
      filterKey: 'gender.id',
      flex: 1,
      editable: false,
      type: 'singleSelect',
      valueOptions:
        genders &&
        genders.data.map(({ id, name }) => ({
          value: id,
          label: name,
        })),
      valueGetter: (params) => params.row?.gender?.name,
    },
    {
      field: 'educationalLevel.name',
      headerName: t('educational-level'),
      filterKey: 'educationalLevel.id',
      flex: 1,
      editable: false,
      type: 'singleSelect',
      valueOptions:
        educationalLevels &&
        educationalLevels.data.map(({ id, name }) => ({
          value: id,
          label: name,
        })),
      valueGetter: (params) => {
        return params.row?.educationalLevel?.name
      },
    },
    {
      field: 'town.name',
      headerName: t('localisation'),
      filterKey: 'town.id',
      flex: 1,
      editable: false,
      type: 'singleSelect',
      valueOptions:
        towns &&
        towns.data.map(({ id, name }) => ({
          value: id,
          label: name,
        })),
      valueGetter: (params) => params.row?.town?.name,
    },
    {
      field: 'roles',
      headerName: t('roles'),
      flex: 1,
      sortable: false,
      filterable: false,
      valueGetter: (params) => params.value?.map((role) => role.key).join(' '),
    },
    {
      field: 'actions',
      type: 'actions',
      width: 100,
      getActions: ({ id }) => [
        <GridActionsCellItem
          icon={<Delete />}
          onClick={() => handleDelete(id)}
          label={t('delete')}
        />,
        <GridActionsCellItem
          onClick={() => navigate(urlcat(USERS_EDIT_PATH, { id }))}
          icon={<Edit />}
          label={t('edit')}
        />,
      ],
    },
  ]

  const defaultSort: GridSortModel = [
    {
      field: 'lastName',
      sort: SortDirection.ASC,
    },
    {
      field: 'firstName',
      sort: SortDirection.ASC,
    },
  ]

  return { columns, defaultSort }
}

export default useUsersGrid
