import { useQueryWrapper } from 'domains/helpers'
import { PaginatedType } from 'domains/types'
import { OFFER_TYPE, OFFER_TYPES } from './templates'
import { OfferType } from './types'

export function useOfferTypesQuery(
  params?,
  options?,
  filtersPaginationAndSort?,
) {
  return useQueryWrapper<PaginatedType<OfferType>>(
    'GET',
    OFFER_TYPES,
    params,
    options,
    filtersPaginationAndSort,
  )
}

export function useOfferTypeQuery(params?, options?) {
  return useQueryWrapper<OfferType>('GET', OFFER_TYPE, params, options)
}
