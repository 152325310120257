import { Box, CircularProgress, useTheme } from '@mui/material'
import { ResponsiveBar } from '@nivo/bar'
import React from 'react'
import {
  BOTTOM_TICK_PADDING,
  BOTTOM_TICK_ROTATION,
  BOTTOM_TICK_SIZE,
  MARGIN_BOTTOM_GRAPH,
  MARGIN_LEFT_GRAPH,
  MAX_VALUE_AGE_RANGE_GRAPH,
  Y_GRID_VALUES,
  Y_TICK_PADDING,
  Y_TICK_ROTATION,
  Y_TICK_SIZE,
} from './constants'

function AgeRange({ statistic = null }) {
  const theme = useTheme()
  if (statistic == null) return <CircularProgress color="secondary" />
  return (
    <Box>
      <Box
        sx={{
          height: theme.spacing(16),
        }}
      >
        <ResponsiveBar
          data={statistic.ageRanges}
          colors={[statistic.ageRanges[0]?.color]}
          theme={{
            axis: {
              ticks: {
                text: {
                  fill: theme.palette.common.white,
                },
              },
            },
          }}
          margin={{ bottom: MARGIN_BOTTOM_GRAPH, left: MARGIN_LEFT_GRAPH }}
          indexScale={{ type: 'band', round: true }}
          maxValue={MAX_VALUE_AGE_RANGE_GRAPH}
          enableLabel={false}
          isInteractive={false}
          borderRadius={5}
          enableGridY
          gridYValues={Y_GRID_VALUES}
          indexBy="age"
          axisTop={null}
          axisRight={null}
          axisLeft={{
            tickValues: Y_GRID_VALUES,
            tickRotation: Y_TICK_ROTATION,
            tickPadding: Y_TICK_PADDING,
            tickSize: Y_TICK_SIZE,
          }}
          axisBottom={{
            tickSize: BOTTOM_TICK_SIZE,
            tickPadding: BOTTOM_TICK_PADDING,
            tickRotation: BOTTOM_TICK_ROTATION,
          }}
        />
      </Box>
    </Box>
  )
}

export default AgeRange
