import { Box, Typography, Paper } from '@mui/material'
import { isEmpty } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

function SatisfactionDashboard({ userRun }) {
  const { t } = useTranslation()
  return (
    <Box>
      <Typography variant="h2" fontWeight={700} sx={{ mb: 2 }}>
        {t('customer-satisfaction')}
      </Typography>
      {!isEmpty(userRun.userAnswers) ? (
        <>
          {userRun.userAnswers.some((a) => a.question.index === 0) && (
            <Paper sx={{ bgcolor: 'primary.main', p: 1.5, mb: 2 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h2" fontWeight={700}>
                  {t('thematic-recommendation')}
                </Typography>

                <Typography variant="h2">
                  {userRun.userAnswers
                    .filter((a) => a.question.index === 0)
                    .map((a) => a.answer)}
                  /10
                </Typography>
              </Box>
            </Paper>
          )}
          {userRun.userAnswers.some((a) => a.question.index === 1) && (
            <Paper sx={{ bgcolor: 'primary.main', p: 1.5, mb: 2 }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h2" fontWeight={700}>
                  {t('run-rating')}
                </Typography>

                <Typography variant="h2">
                  {userRun.userAnswers
                    .filter((a) => a.question.index === 1)
                    .map((a) => a.answer)}
                  /5
                </Typography>
              </Box>
            </Paper>
          )}
          {userRun.userAnswers.some((a) => a.question.index === 2) && (
            <Paper sx={{ bgcolor: 'primary.main', p: 1.5 }}>
              <Typography variant="h2" fontWeight={700}>
                {t('free-expression')}
              </Typography>
              <Typography sx={{ wordWrap: 'break-word' }}>
                {userRun.userAnswers
                  .filter((a) => a.question.index === 2)
                  .map((a) => a.answer)}
              </Typography>
            </Paper>
          )}
        </>
      ) : (
        <Typography sx={{ mt: 2 }}>{t('no-answers')}</Typography>
      )}
    </Box>
  )
}

export default SatisfactionDashboard
