import { alpha, Components } from '@mui/material'
import { black, coral, coralLight, ivory } from './palette'

const components: Components = {
  MuiInputLabel: {
    styleOverrides: {
      shrink: {
        color: black,
        '&.Mui-focused': {
          color: black,
        },
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        overflow: 'initial',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      notchedOutline: {
        border: 'none',
      },
      root: {
        backgroundColor: coralLight,
      },
    },
  },
  MuiSkeleton: {
    styleOverrides: {
      root: {
        backgroundColor: alpha(coral, 0.4),
        borderRadius: 5,
      },
    },
    defaultProps: {
      animation: 'wave',
    },
  },
  MuiButton: {
    defaultProps: {
      variant: 'contained',
    },
  },
  MuiFab: {
    styleOverrides: {
      root: {
        backgroundColor: black,
        color: ivory,
        '&:hover': {
          backgroundColor: alpha(black, 0.8),
        },
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        color: black,
        '&: .Mui-focused': { color: black },
      },
    },
  },
  MuiRadio: {
    styleOverrides: {
      root: {
        color: black,
      },
    },
  },
}

export default components

// https://mui.com/material-ui/customization/theme-components/
