import { Tabs } from '@mui/material'
import LinkTab from 'components/atoms/LinkTab'
import Header from 'components/molecules/Header'
import { last } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation } from 'react-router-dom'

const TEMPLATES = 'templates'
const LAYOUT = 'layout'
const FUSION_FIELDS = 'fusion-fields'
const TEST = 'test'
function MailManagement() {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const lastPathnamePart = last(pathname.split('/'))
  const initialValue = [TEMPLATES, LAYOUT, FUSION_FIELDS, TEST].includes(
    lastPathnamePart,
  )
    ? lastPathnamePart
    : TEMPLATES
  const [value, setValue] = React.useState(initialValue)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <Header title={t('mails')} />
      <Tabs value={value} onChange={handleChange}>
        <LinkTab to="templates" value={TEMPLATES} label={t('mail-templates')} />
      </Tabs>
      <Outlet />
    </>
  )
}

export default MailManagement
