import { Lock } from '@mui/icons-material'
import { Button, Paper } from '@mui/material'
import ControlledTextField from 'components/molecules/ControlledTextField'
import { useAuth } from 'contexts/AuthContext'
import { ERROR, useSnackbar } from 'contexts/SnackbarContext'
import { HOME_PATH } from 'enums/paths'
import { get } from 'lodash'
import React from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import useLoginForm from './form'

function LoginForm() {
  const { t } = useTranslation()
  const form = useLoginForm()
  const { login } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const from = get(location, 'state.from.pathname', HOME_PATH)
  const { popSnackbar } = useSnackbar()

  const onSubmit = async (values) => {
    return login(values)
      .then(() => navigate(from, { replace: true }))
      .catch((error) => {
        popSnackbar(
          error.response?.data?.email || error.response?.data?.password,
          ERROR,
        )
      })
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...form}>
      <Paper
        component="form"
        onSubmit={form.handleSubmit(onSubmit)}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          padding: 2,
          bgcolor: 'primary.main',
          width: 400,
        }}
      >
        <Lock
          className="animate__animated animate__headShake"
          fontSize="large"
          sx={{ alignSelf: 'center', color: 'background.default' }}
        />
        <ControlledTextField name="email" label={t('email')} />
        <ControlledTextField
          name="password"
          type="password"
          label={t('password')}
        />
        <Button color="secondary" variant="contained" type="submit">
          {t('login')}
        </Button>
      </Paper>
    </FormProvider>
  )
}

export default LoginForm
