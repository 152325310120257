import axios from 'axios'
import { ERROR, useSnackbar, WARNING } from 'contexts/SnackbarContext'
import {
  axiosInstance,
  clearJwtToken,
  getJwtToken,
  setJwtToken,
} from 'domains/helpers'
import { REFRESH_TOKEN } from 'domains/users/templates'
import { UNAUTHORIZED } from 'enums/httpCodes'
import { LOGIN_PATH } from 'enums/paths'
import { first, flatten, get, isEmpty, values } from 'lodash'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAuth } from './AuthContext'

let alreadyRefreshing = false
const pendingRequests = []

export function AxiosInterceptors({ children }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { logout } = useAuth()
  const { popSnackbar } = useSnackbar()

  const unauthorized = useCallback(
    (error?) => {
      popSnackbar(t('must-authenticate'), WARNING)
      clearJwtToken()
      navigate(LOGIN_PATH)
      return error
    },
    [navigate, popSnackbar, t],
  )

  const resumePendingRequests = () => {
    while (!isEmpty(pendingRequests)) {
      const { req } = pendingRequests.shift()
      req.headers = {
        ...req.headers,
        Authorization: `Bearer ${getJwtToken()}`,
      }
      axios.request(req)
    }
  }

  const refreshTokenAndTryAgain = useCallback(
    (error) => {
      const req = error.config
      pendingRequests.push({ req })

      if (alreadyRefreshing) {
        return
      }

      alreadyRefreshing = true

      axios
        .post(REFRESH_TOKEN, null, {
          headers: {
            Authorization: `Bearer ${getJwtToken()}`,
          },
          withCredentials: true,
          baseURL: process.env.REACT_APP_API_URL,
        })
        .then((response) => response.data)
        .then((jwtToken) => {
          if (isEmpty(jwtToken)) {
            logout()
            unauthorized()
            return
          }

          setJwtToken(jwtToken)
          resumePendingRequests()
        })
        .catch((err) => {
          logout()
          unauthorized(err)
        })
        .finally(() => {
          alreadyRefreshing = false
        })
    },
    [logout, unauthorized],
  )
  const popSnackbarIfAvailable = (error) => {
    try {
      const data = get(error, 'response.data')
      if (typeof data === 'string') {
        return popSnackbar(data, ERROR)
      }
      const firstError = first(flatten(values(data)))
      if (!isEmpty(firstError)) {
        return popSnackbar(firstError, ERROR)
      }
      if (error.response && error.response.status !== UNAUTHORIZED) {
        return popSnackbar(t('error'), ERROR)
      }
      return null
    } catch (err) {
      return null
    }
  }

  useEffect(() => {
    const authInterceptor = axiosInstance.interceptors.response.use(
      (response) => {
        return response
      },
      (error) => {
        popSnackbarIfAvailable(error)
        switch (error.response.status) {
          case 401:
            return refreshTokenAndTryAgain(error)
          default:
            return Promise.reject(error)
        }
      },
    )

    return () => axiosInstance.interceptors.response.eject(authInterceptor)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return children
}
