/* eslint-disable react/jsx-props-no-spreading */
import { alpha, Box, Paper, Typography } from '@mui/material'
import React from 'react'
import RunCardHeader from '../RunCardHeader'
import RunCardInfos from '../RunCardInfos'
import { Props } from './types'
import { MIN_HEIGHT_RUN_CARD } from './constants'

function RunCard({ run }: Props) {
  return (
    <Paper
      sx={{
        backgroundImage: `url(${run.picture?.url})`,
        backgroundSize: 'cover',
        borderRadius: '4px',
        height: MIN_HEIGHT_RUN_CARD,
      }}
    >
      <Box
        sx={{
          bgcolor: (theme) => alpha(theme.palette.common.black, 0.4),
          width: '100%',
          height: '100%',
          borderRadius: '4px',
          p: 1,
        }}
      >
        <RunCardHeader run={run} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: (theme) => `calc(100% - ${theme.spacing(5)})`,
          }}
        >
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                width: '100%',
                mt: 1,
              }}
            >
              <Typography
                textAlign="center"
                variant="h4"
                sx={{
                  color: 'text.secondary',
                }}
              >
                {run.name}
              </Typography>
            </Box>
          </Box>
          <RunCardInfos run={run} />
        </Box>
      </Box>
    </Paper>
  )
}

export default RunCard
