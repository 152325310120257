import { Delete, Edit, Loop } from '@mui/icons-material'
import { Box, IconButton, Tooltip } from '@mui/material'
import { useConfirm } from 'contexts/ConfirmContext'
import { ERROR, SUCCESS, useSnackbar } from 'contexts/SnackbarContext'
import { useDeleteRunMutation } from 'domains/runs/mutations'
import { RUNS } from 'domains/runs/templates'
import { RUNS_EDIT_PATH } from 'enums/paths'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import urlcat from 'urlcat'

function StyledIconButton(props) {
  return (
    <IconButton size="small" sx={{ color: 'background.default' }} {...props} />
  )
}

function RunCardHeader({ run }) {
  const navigate = useNavigate()
  const { confirm } = useConfirm()
  const { t } = useTranslation()
  const deleteRun = useDeleteRunMutation()
  const queryClient = useQueryClient()
  const { popSnackbar } = useSnackbar()
  const handleDelete = () => {
    confirm(t('run-delete-confirm'), t('irreversible-action'))
      .then(() =>
        deleteRun.mutateAsync(
          { id: run.id },
          {
            onSuccess: () => {
              queryClient.invalidateQueries(RUNS)
              popSnackbar(t('run-delete-success'), SUCCESS)
            },
            onError: () => {
              popSnackbar(t('error'), ERROR)
            },
          },
        ),
      )
      .catch(() => null)
  }
  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      {run.isLoop && (
        <Tooltip title={t('loop-run')}>
          <Loop sx={{ color: 'text.secondary', m: 0.5 }} />
        </Tooltip>
      )}
      {run.isActive && (
        <Tooltip title={t('actived')}>
          <CheckCircleOutlineIcon sx={{ color: 'text.secondary', m: 0.5 }} />
        </Tooltip>
      )}
      <Box sx={{ flex: 1 }} />
      <StyledIconButton
        onClick={() => navigate(urlcat(RUNS_EDIT_PATH, { id: run.id }))}
      >
        <Edit />
      </StyledIconButton>
      <StyledIconButton onClick={() => handleDelete()}>
        <Delete />
      </StyledIconButton>
    </Box>
  )
}

export default RunCardHeader
