import { useQueryWrapper } from 'domains/helpers'
import { PaginatedType } from 'domains/types'
import { PARTNER, PARTNERS } from './templates'
import { Partner } from './types'

export function usePartnersQuery(params?, options?, filtersPaginationAndSort?) {
  return useQueryWrapper<PaginatedType<Partner>>(
    'GET',
    PARTNERS,
    params,
    options,
    filtersPaginationAndSort,
  )
}

export function usePartnerQuery(params?, options?) {
  return useQueryWrapper<Partner>('GET', PARTNER, params, options)
}
