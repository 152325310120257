import React from 'react'
import { useController } from 'react-hook-form'
import { ControlledComponentProps } from 'types'

function ControlledHiddenInput({ name, control }: ControlledComponentProps) {
  const controller = useController({ name, control })
  const { field } = controller

  if (field.value === undefined) {
    console.error(
      `[FTEL-ERROR] : Le composant ControlledHiddenInput '${name}' a une valeur undefined, il faut impérativement donner une valeur par défaut au formulaire (defaultValues)"`,
    )
  }

  return <input type="hidden" {...field} value={field.value ?? ''} />
}

export default ControlledHiddenInput
